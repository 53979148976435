import React, { useState, useEffect } from 'react';
import {
  Box, TextField, List, ListItem, ListItemText, Checkbox, Button, Typography, IconButton, Tooltip, Collapse, Chip, Divider, CircularProgress, InputAdornment
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import ClearIcon from '@mui/icons-material/Clear';
import { useAppContext } from '../contexts/AppContext';

const ACAssign = ({ selectedDocuments, onAssign, searchTerm, onSearchChange, onRemoveDocument, assignments }) => {
  const [selectedCriteria, setSelectedCriteria] = useState(assignments || {});
  const [currentDoc, setCurrentDoc] = useState(null);
  const [criteria, setCriteria] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterDaysAgoMap, setFilterDaysAgoMap] = useState({}); // Store filterDaysAgo for each document

  const { getAcceptanceCriteria } = useAppContext();

  // Calculate the days ago from filter_start_date
  const calculateDaysAgo = (filterStartDate) => {
    if (!filterStartDate) return 7; // Default to 7 days for new documents
    const startDate = new Date(filterStartDate);
    const today = new Date();
    const timeDiff = today - startDate;
    return Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Convert time difference to days
  };

  useEffect(() => {
    if (currentDoc) {
      const fetchCriteria = async () => {
        setLoading(true);
        const criteria = await getAcceptanceCriteria();
        setCriteria(criteria);
        setLoading(false);
      };
      fetchCriteria();
    }
  }, [currentDoc, getAcceptanceCriteria]);

  useEffect(() => {
    // Initialize the filterDaysAgo for each document based on filter_start_date
    const newFilterDaysAgoMap = {};
    selectedDocuments.forEach(doc => {
      const daysAgo = calculateDaysAgo(doc.file_metadata.filter_start_date);
      newFilterDaysAgoMap[doc.file_metadata.id] = daysAgo;
    });
    setFilterDaysAgoMap(newFilterDaysAgoMap); // Ensure state updates with new paths
  }, [selectedDocuments]);

  const handleSearchChange = (e) => {
    onSearchChange(e.target.value);
  };

  const handleClearSearch = () => {
    onSearchChange('');
  };

  const handleFunctionSelect = (func) => {
    if (currentDoc) {
      setSelectedCriteria((prev) => {
        const currentCriteria = prev[currentDoc.file_metadata.id] || [];
        const isSelected = currentCriteria.some((f) => f.name === func.name);
        const updatedCriteria = isSelected
          ? currentCriteria.filter((f) => f.name !== func.name)
          : [...currentCriteria, func];
        return { ...prev, [currentDoc.file_metadata.id]: updatedCriteria };
      });
    }
  };

  const handleDocumentSelect = (doc) => {
    setCurrentDoc(currentDoc === doc ? null : doc);
  };

  const handleDaysChange = (e, doc) => {
    const days = parseInt(e.target.value, 10);
  
    if (isNaN(days) || days < 0) {
      return; // Prevent invalid date changes (e.g., negative or non-numeric input)
    }
  
    setFilterDaysAgoMap((prev) => ({
      ...prev,
      [doc.file_metadata.id]: days
    }));
  
    // Update filter_start_date in the selectedDocuments array
    const today = new Date();
    const newStartDate = new Date();
  
    newStartDate.setDate(today.getDate() - days);
  
    // Ensure the date is valid before converting to ISO string
    if (!isNaN(newStartDate.getTime())) {
      doc.file_metadata.filter_start_date = newStartDate.toISOString().split('.')[0];

      // Immediately pass updated document back to parent via onAssign
      onAssign(selectedDocuments.map((updatedDoc) => ({
        ...updatedDoc,
        filter_start_date: updatedDoc.file_metadata.filter_start_date,
        filter_end_date: updatedDoc.file_metadata.filter_end_date,
      })));
    } else {
      console.error('Invalid date calculation for filter_start_date');
    }
  };
  

  const handleAssign = () => {
    if (currentDoc) {
      onAssign(currentDoc.file_metadata.id, selectedCriteria[currentDoc.file_metadata.id] || []);
      setCurrentDoc(null);
    }
  };

  const handleRemoveCriteria = (docId, func) => {
    setSelectedCriteria((prev) => {
      const currentCriteria = prev[docId] || [];
      const updatedCriteria = currentCriteria.filter((f) => f.name !== func.name);
      
      // Update the state locally for immediate UI feedback
      const newCriteria = { ...prev, [docId]: updatedCriteria };
      setSelectedCriteria(newCriteria);
  
      // Immediately propagate the changes back to the parent component
      onAssign(docId, updatedCriteria);
      
      return newCriteria;
    });
  };
  

  const filteredCriteria = criteria.filter((func) =>
    func.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    setSelectedCriteria(assignments);
  }, [assignments]);

  return (
    <React.Fragment>
      <Typography variant="body2" gutterBottom align="left">
        Berichtquellen
      </Typography>
      <div style={{ padding: '0.5rem' }}>
        {selectedDocuments.length === 0 ? (
          <Typography>Dokumente auswählen, um Funktionen zuzuweisen</Typography>
        ) : (
          <List>
            {selectedDocuments.map((doc) => (
              <div key={doc.file_metadata.id}>
                <ListItem>
                  {doc.file_metadata.is_dir ? <FolderIcon sx={{paddingRight: 0.5}}/> : <InsertDriveFileIcon sx={{paddingRight: 0.5}} />}
                  <ListItemText
                    primary={doc.file_metadata?.name || 'No Name'}
                    secondary={doc.file_metadata?.path || 'No Path'}
                  />
                  
                    {doc.file_metadata.is_dir && (
                    <TextField
                      label="Filter nach Tagen"
                      type="number"
                      value={filterDaysAgoMap[doc.file_metadata.id] || 7} // Initialize value from state
                      onChange={(e) => handleDaysChange(e, doc)}
                      inputProps={{ min: 1 }}
                      sx={{ width: '100px', ml: 2 }}
                    />
                  )}
                  <Tooltip title="Akzeptanzkriterien hinzufügen">
                    <IconButton
                      sx={{ backgroundColor: currentDoc === doc ? 'primary.main' : 'transparent', color: currentDoc === doc ? 'white' : 'inherit' }}
                      onClick={() => handleDocumentSelect(doc)}
                    >
                      {loading ? <CircularProgress size={24} /> : <AddIcon />}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Akzeptanzkriterien sind vordefinierte, aber dynamische Regelsätze, die Ihre Inhalte validieren. Wählen Sie die Kriterien aus, die Sie auf jedes Dokument anwenden möchten.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                  <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                  <IconButton
                    edge="start"
                    onClick={() => onRemoveDocument(doc)}
                    sx={{ marginRight: 1 }}
                  >
                    <DeleteIcon />
                  </IconButton>

                  
                </ListItem>
                {selectedCriteria[doc.file_metadata.id] && selectedCriteria[doc.file_metadata.id].length > 0 && (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, paddingLeft: 4 }}>
                    {selectedCriteria[doc.file_metadata.id].map((func) => (
                      <Chip
                        key={func.name}
                        label={func.name}
                        onDelete={() => handleRemoveCriteria(doc.file_metadata.id, func)}
                        deleteIcon={<CancelIcon />}
                        sx={{ margin: 0.5 }}
                      />
                    ))}
                  </Box>
                )}
                <Collapse in={currentDoc === doc} timeout="auto" unmountOnExit>
                  <div>
                    <TextField
                      label="Akzeptanzkriterien Suchen"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      fullWidth
                      sx={{ marginY: 2 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClearSearch}>
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <List
                      sx={{
                        maxHeight: '200px', // approximately 5 rows
                        overflow: 'auto',
                      }}
                    >
                      {filteredCriteria.map((func) => (
                        <ListItem
                          key={func.name}
                          /* button */
                          onClick={() => handleFunctionSelect(func)}
                        >
                          <Checkbox
                            checked={(selectedCriteria[currentDoc?.file_metadata.id] || []).some((f) => f.name === func.name)}
                            tabIndex={-1}
                            disableRipple
                          />
                          <ListItemText primary={func.name} secondary={func.description} />
                        </ListItem>
                      ))}
                    </List>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                      <Button 
                        variant="contained" 
                        onClick={handleAssign}
                      >
                        Akzeptanzkriterien anwenden
                      </Button>
                    </Box>
                  </div>
                </Collapse>
              </div>
            ))}
          </List>
        )}
      </div>
    </React.Fragment>
  );
};

export default ACAssign;
