import React from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { Chip } from '@mui/material';
import config from '../config';

const ReportInstanceListView = ({ rows, filterMethod }) => {
  const columns = [
    { field: 'id', headerName: 'ID', flex:0.5 },
    { field: 'type', headerName: 'Bewertungsart', flex: 0.25,
      renderCell: (params) => <Chip label={params.row.type} />
    },
    { field: 'title', headerName: 'Vorlage ID', flex: 0.75 },
    { field: 'result', headerName: 'Ergebnis', flex: 0.75 },
    { field: 'creation_date', headerName: 'Erstellungsdatum', flex: 0.5 },
  ];

  const type = filterMethod === 'Bewertung' ? 'Acceptance Criteria' : 'Acceptance Criteria';
  const filteredRows = rows
    .filter(row => row.result && typeof row.result === 'string' && row.result.includes(filterMethod))
    .map(row => ({ ...row, type }));

  return (
    <DataGridPro 
      autoHeight 
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
        pagination: { paginationModel: { pageSize: config.ui_settings.pagination_page_size } },
      }}
      pagination
      pageSizeOptions={config.ui_settings.pagination_page_size_options}
      rows={filteredRows} 
      columns={columns} 
      slots={{ toolbar: GridToolbar }}
      checkboxSelection
      disableRowSelectionOnClick
      disableMultipleSelection 
      sx={{
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: 'lightgrey',
        },
      }}
    />
  );
};

export default ReportInstanceListView;
