import React, { useState, useEffect } from 'react';
import { Modal, LinearProgress, Chip, Box, Typography, AppBar, Toolbar, IconButton, Button, Grid, Divider, TextField, Checkbox, FormControlLabel, MenuItem, Select, InputLabel, FormControl, Alert, OutlinedInput, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AssistantIcon from '@mui/icons-material/Assistant';
import Markdown from 'markdown-to-jsx';
import { useAppContext } from '../contexts/AppContext';
import { useAuth } from '../contexts/AuthContext';
import config from '../config';
import documentData from '../intermediate_document_data.json';
import '../styles/Assistant.css'

function Assistant() {
    const [open, setOpen] = useState(false);
    const [currentProcess, setCurrentProcess] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({ additionalInfo: [] }); // Store additional information provided by user
    const [selectedProject, setSelectedProject] = useState('');
    const [activatedContexts, setActivatedContexts] = useState([]);
    const [acceptanceCriteria, setAcceptanceCriteria] = useState([]);
    const [selectedCriteria, setSelectedCriteria] = useState([]);
    const [modelResponse, setModelResponse] = useState('');
    const [questionAnswers, setQuestionAnswers] = useState({});
    const [isEditing, setIsEditing] = useState(false); // Track if the user is in editing mode
    const [editableResponse, setEditableResponse] = useState('');
    const [isProcessing, setIsProcessing] = useState(false); // Track if processing is happening
    const [executionStep, setExecutionStep] = useState(''); // Track background steps during execution
    const [animationClass, setAnimationClass] = useState('');
    const [phases, setPhases] = useState([]);
    const [fachbereiche, setFachbereiche] = useState([]);
    const [selectedPhase, setSelectedPhase] = useState('');
    const [selectedFachbereiche, setSelectedFachbereiche] = useState([]);


    const { userProjects, getAcceptanceCriteria, queryGenerativeModel, getSearchResultsInDocsStatelessV2, fetchReportResults, getProject, getExtractedData } = useAppContext();
    const { currentTenant } = useAuth();

    const max_elements = 5;
    const max_depth = 3;

    // Fetch projects and acceptance criteria on component load
    useEffect(() => {
        if (open) {
            getAcceptanceCriteria().then((criteria) => setAcceptanceCriteria(criteria));
        }
    }, [open]);

     // On page reload, trigger background color change a few times
    useEffect(() => {
        // Start the animation by adding the class
        setAnimationClass('pulse-animation');

        // Remove the animation class after a few seconds
        const timeout = setTimeout(() => {
            setAnimationClass('');
        }, 3000); // Animation duration is 3 seconds, adjust as necessary

        // Cleanup the timeout on component unmount
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if (currentProcess?.name === 'Projektdokumentation') {
            const uniquePhases = [...new Set(documentData.flatMap(doc => doc.sia_phasen))];
            const uniqueFachbereiche = [...new Set(documentData.flatMap(doc => doc.fachbereiche))];
            setPhases(uniquePhases);
            setFachbereiche(uniqueFachbereiche);
            /* setSelectedPhase(getPhaseName([currentProcess?.predicted_phase]) || uniquePhases[0]); */
        }
    }, [currentProcess]);
    

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setCurrentProcess(null);
        setCurrentStep(0);
        setFormData({ additionalInfo: [] });
        setSelectedCriteria([]);
        setActivatedContexts([]);
        setModelResponse('');
        setQuestionAnswers({});
        setFachbereiche([]);
        setSelectedPhase('');
        setSelectedFachbereiche([]);
    };

    const handlePrevStep = () => {
        setCurrentStep((prevStep) => {
            // If on the first step, reset the process name by setting currentProcess to null
            if (prevStep === 0) {
                setCurrentProcess(null);
                setCurrentStep(0);
                setFormData({ additionalInfo: [] });
                setSelectedCriteria([]);
                setActivatedContexts([]);
                setModelResponse('');
                setQuestionAnswers({});
                setFachbereiche([]);
                setSelectedPhase('');
                setSelectedFachbereiche([]);
            }
            return prevStep - 1;
        });
    };
    
    const handleProcessSelect = (processName) => {
        const selectedProcess = processes.find(process => process.name === processName);
    
        // Safeguard for undefined questions
        if (!selectedProcess.questions) {
            selectedProcess.questions = []; // Initialize questions as an empty array if undefined
        }
    
        setCurrentProcess(selectedProcess);
        setCurrentStep(0);
        setActivatedContexts(selectedProcess.contexts || []); // Ensure contexts is always an array
    };
    

    const handleProjectSelect = (event) => {
        setSelectedProject(event.target.value);
    };

    const handleCriteriaSelect = (event) => {
        const {
            target: { value }
        } = event;
    
        // Get full criteria objects based on selected values
        const selectedCriteriaObjects = acceptanceCriteria.filter(criteria => 
            value.includes(criteria.name)
        );
    
        setSelectedCriteria(selectedCriteriaObjects); // Save the whole criteria object instead of just names
    };
    

    const handleAnswerChange = (index, value) => {
        setQuestionAnswers((prevAnswers) => ({
            ...prevAnswers,
            [index]: value
        }));
    };

    const handleContextToggle = (context) => {
        const newContexts = activatedContexts.includes(context)
            ? activatedContexts.filter(c => c !== context)
            : [...activatedContexts, context];
        setActivatedContexts(newContexts);
    };

     // Handle user adding more information
     const handleAddAdditionalInfo = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            additionalInfo: [...prevFormData.additionalInfo, ''] // Add empty field
        }));
    };

    const handleAdditionalInfoChange = (index, value) => {
        const updatedInfo = [...formData.additionalInfo];
        updatedInfo[index] = value;
        setFormData({ ...formData, additionalInfo: updatedInfo });
    };

    const handleRemoveAdditionalInfo = (index) => {
        const updatedInfo = formData.additionalInfo.filter((_, i) => i !== index);
        setFormData({ ...formData, additionalInfo: updatedInfo });
    };

    const handleEditToggle = () => {
        if (isEditing) {
            // Save the changes and update the model response
            setModelResponse(editableResponse);
            setIsEditing(false);
        } else {
            // Enter editing mode and populate the editable field
            setEditableResponse(modelResponse);
            setIsEditing(true);
        }
    };

    const isFormValid = () => {
        // Check if all required fields are filled
        const isProjectSelected = !!selectedProject;
        const areQuestionsAnswered = currentProcess?.questions.every((_, index) => questionAnswers[index]);
        const isAdditionalInfoFilled = formData.additionalInfo.every(info => info.trim() !== '');
        const isAtLeastOneContextSelected = activatedContexts.length > 0;
        
        const isProjektdokumentation = currentProcess?.name === "Projektdokumentation";

        const areEnoughFachbereicheSelected = selectedFachbereiche.length >= 1;
        const isPhaseSelected = selectedPhase !== '';
    
        if (isProjektdokumentation) {
            return isProjectSelected && areQuestionsAnswered && isAdditionalInfoFilled && isAtLeastOneContextSelected && isPhaseSelected && areEnoughFachbereicheSelected;
        } else {
            return isProjectSelected && areQuestionsAnswered && isAdditionalInfoFilled && isAtLeastOneContextSelected;
        }
    };
    
    const processes = [
        {
            name: "Pflichtenheft",
            category: "Vertragrelevant",
            prompt: "The output should be in German swiss style writing. Ich möchte ein Pflichtenheft erstellen. BItte berücksichtige andere bereits vorhandenen Pflichtenhefte und grenze das aktuelle von denen ab. Bitte erstelle auf Grundlage von relevanten Projektinformationen, Feststellung, Bewertungen ein Pflichtenheft und benenne die Firmen mit Namen und Adresse, Einleitung und Zielsetzung, Anforderungen, Rahmenbedingungen, Spezifikationen, Schnittstellen, Qualitätsanforderungen, Verifizierung und Validierung, Änderungsmanagement, Glossar.",
            contexts: ["projektdetails", "vorgaben", "strategie", "berichte"], // Pre-selected appropriate contexts
            description: "Erstellen Sie ein Anforderungsdokument für das Projekt.",
            questions: ["Was sind die Anforderungen?", "Welche Abteilungen sind beteiligt?"],
            output: 'Dokument'
        },
        {
            name: "Projektdokumentation",
            category: "Vertragrelevant",
            prompt: "The output should be in German swiss style writing. Output only the results. You will be provided with a list of required documents according to KBOB in the context named 'kbob'. Format the list so that it is grouped by Fachbereich, and keep all details provided. If the context 'kbob' is empty, respond with 'keine Dokumente entsprechen den angegebenen Filtern.'",
            contexts: ["projektdetails", "kbob"], 
            description: "Erstellen Sie eine Übersicht nach KBOB über die Abschlussdokumentation des Projekts.",
            questions: [],
            output: 'Dokument'
        },
        {
            name: "Mängelrüge",
            category: "Vertragrelevant",
            prompt: "The output should be in German swiss style writing. Wir haben einen Vertrag mit einem Unternehmer. Welche Rechtsgrundlage und Paragrafen nach schweizer Recht sind anwendbar? Bitte erstelle auf Grundlage dieser Feststellung, relevanten Protokollen der Vertragsgrundlage eine Mängelrüge als Anschreiben und benenne beide Firmennamen, Adressen, Ansprechpartner und dne Betreff des Mangels. Wenn ein klarer Mangel und der Bewergrund im Protokoll festgestellt und beschrieben worden ist und dieser zur aktuellen PRoblemstellung passt bitte diesen explizit aufnehmen.",
            contexts: ["projektdetails", "strategie", "protokolle"],
            description: "Melden Sie Mängel im Projekt.",
            questions: ["Welche Mängel wurden gefunden?", "Wer ist verantwortlich?"],
            output: 'Dokument'
        },
        {
            name: "Nachtragspotenzial Analyse",
            category: "Berichtrelevant",
            prompt: "The output should be in German swiss style writing. Erstelle eine Bewertung für das Nachtragspotenzial und Identifiziere wesentliche Risiken auf Basis von: den individuellen Zielen vom Fachbereich, der aktuellen Aufgaben, Änderung der Projektanforderungen, Risiken und unvorhergesehene Ereignisse, Kommunikationsprobleme, Vertragslücken, Technische Herausforderungen, Datenschutzverletzungen erstelle ein eEinschätzung zu den thematische Beiträge die zu Nachtragspotenzialen führen können.",
            contexts: ["projektdetails", "strategie", "protokolle", "aufgaben", "vorgaben"],
            description: "Analysieren Sie potenzielle Nachtragsforderungen.",
            questions: ["Welche Nachträge sind offen?", "Welche Fristen gibt es?"],
            output: 'Dokument'
        },
        {
            name: "Risikobericht",
            category: "Berichtrelevant",
            prompt: "The output should be in German swiss style writing. Erstelle einen Risikobericht unter Berücksichtigung folgender Aspakte: Änderung der Projektanforderungen, Risiken und unvorhergesehene Ereignisse, Kommunikationsprobleme, Vertragslücken, Technische Herausforderungen, Datenschutzverletzungen.",
            contexts: ["projektdetails", "strategie", "berichte", "vorgaben"],
            description: "Erstellen Sie einen Risikobericht für das Projekt.",
            questions: ["Welche Risiken bestehen?", "Welche Maßnahmen wurden getroffen?"],
            output: 'Dokument'
        },
        {
            name: "Koordinationsbericht",
            category: "Berichtrelevant",
            prompt: "The output should be in German swiss style writing. Erstelle einen Koordinationsbericht unter Berücksichtigung der Bericht und wesentlichen identifizieren Aufgaben. Die Prorisiung erfolgt unter Berücksichtungung entsprechender Fachbereiche, zugewiesen und Fälligkeitsdaten. Bitte die Aufgaben chronologisch auflisten.",
            contexts: ["projektdetails", "vorgaben", "aufgaben", "berichte"],
            description: "Erstellen Sie einen Koordinationsbericht für verschiedene Teams.",
            questions: ["Welche Teams sind beteiligt?", "Was ist der Fortschritt der Teams?"],
            output: 'Tabelle'
        },
        {
            name: "Aufgabenanalyse und Priorisierung",
            category: "Berichtrelevant",
            prompt: "The output should be in German swiss style writing. Berücksichtige die Aufgaben der letzten Woche. Bitte Auflistung der Aufgaben gruppiert nach Fachbereichen, priorisiert nach Fälligkeitsdatum. Zusammen mit der Information über den Ablageort. Bedingungen: wenn kein Fachbereich benannt einen Fachbereich vorschlagen, wenn kein Fälligkeitsdatum vorhanden, ein Fälligkeitsdatum im Kontext der Aufgaben vorschlagen. Alle Vorschläge mittels dem Verweis 'nukleus Vorschlag' identifizieren.",
            contexts: ["projektdetails", "aufgaben"],
            description: "Analysieren und priorisieren Sie Aufgaben im Projekt.",
            questions: ["Welche Aufgaben sind offen?", "Welche Prioritäten wurden gesetzt?"],
            output: 'Tabelle'
        }
    ];
      
    const steps = {
        0:'Konfiguration',
        1:'Ergebnisse'
    }

    const context_dir = {
        'strategie': { path: `/${currentTenant}/${selectedProject}/Strategie`, label: 'Strategie' },
        'protokolle': { path: `/${currentTenant}/${selectedProject}/Protokolle`, label: 'Protokolle' },
        'vorgaben': { path: `/${currentTenant}/Vorgaben Vorlagen und Richtlinien`, label: 'Vorgaben' },
        'kbob': { path: `/${currentTenant}/Vorgaben Vorlagen und Richtlinien/Projektdokumentation`, label: 'KBOB' },
        'berichte': { path: ``, label: 'Berichte' },
        'projektdetails': { path: ``, label: 'Projektdetails' },
        'aufgaben': { path: ``, label: 'Aufgaben' }
    };

    const handleExecute = async () => {
        if (!isFormValid()) return; // Ensure form is valid before proceeding

        const isProjektdokumentation = currentProcess?.name === "Projektdokumentation"

        setIsProcessing(true); 
        setExecutionStep('Vorbereitung der Daten und Suchparameter...');
        const contextData = {};
        const projectPath = `/${currentTenant}/${selectedProject}`;

        let project_details = {};

        // Include questions and corresponding answers in the search string
        const questionAnswerPairs = Object.entries(questionAnswers).map(
            ([index, answer]) => `${currentProcess.questions[index]}: ${answer}`
        );

        const additionalInfoPairs = formData.additionalInfo.map((info, index) => `Zusätzliche Information ${index + 1}: ${info}`);

        const searchString = [
            currentProcess.name, // Process name
            currentProcess.category, // Process category
            currentProcess.description, // Process description
            ...questionAnswerPairs, // Add question-answer pairs
            ...additionalInfoPairs // Additional info provided by the user
        ].filter(Boolean).join(', '); // Join them as a comma-separated string

        setExecutionStep('Abrufen der Daten aus den gewählten Kontexten...');

        for (const context of activatedContexts) {
            const contextPath = context_dir[context].path;
            if (context === 'strategie' || context === 'protokolle' || context === 'vorgaben') {
                let matches = [];
                try {
                    matches = await getSearchResultsInDocsStatelessV2(
                        config.envs[config.active_env].domain + config.api_path,
                        max_elements,
                        max_depth,
                        contextPath,
                        searchString
                    );
                } catch (error) {
                    console.error(`Failed to fetch matches from ${context_dir[context].label}:`, error);
                }
                const contextString = matches.map(match => 
                    match.text_sections.map(section => section.text).join(', ')
                ).join(', ');
    
                // Only add non-empty strings to contextData
                if (contextString) {
                    contextData[context] = contextString;
                }
            } else if (context === 'berichte') {
                try {
                    //setLoading(true);
                    const result = await fetchReportResults();
                    // Get today's date in YYYY-MM-DD format for comparison
                    const today = new Date().toISOString().split('T')[0];
                    
                    // Filter data by project and exclude reports created today
                    const filteredData = result
                        .filter(item => item.project === selectedProject && item.creation_date !== today)
                        .sort((a, b) => new Date(b.creation_date) - new Date(a.creation_date)); // Sort by creation_date, most recent first
                    
                    // Filter out the last 5 reports (most recent)
                    const lastNReportsFiltered = filteredData.slice(max_elements);
                    const reportsString = lastNReportsFiltered.map(report => {
                        const documentNames = report.document && typeof report.document === 'object'
                            ? Object.values(report.document).map(doc => doc.name).join(', ')
                            : 'Keine Dokumente';
                        return `Dokumente: ${documentNames}, Name: ${report.name}, Erstellungsdatum: ${report.creation_date}, Text: ${report.text}`;
                    }).join('; ');

                    if (reportsString) {
                        contextData[context] = reportsString;
                    }
                } catch (error) {
                    console.error('Failed to fetch report results:', error);
                }
                // Filter reports and gather data
            } else if (context === 'projektdetails') {
                try {
                    const projectDetailsArray = await getProject(projectPath);
                    if (projectDetailsArray && projectDetailsArray.length > 0) {
                        const projectDetails = projectDetailsArray[0];
                        const { 
                            start_date = '', 
                            end_date = '', 
                            project_class = 'Unbekannt', 
                            project_category = 'Unbekannt', 
                            predicted_phase = 'Unbekannt', 
                            name = 'Unbekannt' 
                        } = projectDetails;

                        project_details =  {
                            start_date: start_date, 
                            end_date: end_date, 
                            project_class: project_class, 
                            project_category: project_category, 
                            predicted_phase: predicted_phase, 
                            name: name 
                        };

                        // Prüfen, ob `start_date` und `end_date` gültige Datumswerte sind, bevor du versuchst, sie zu formatieren
                        const startDateFormatted = start_date ? new Date(start_date).toLocaleDateString() : 'Datum unbekannt';
                        const endDateFormatted = end_date ? new Date(end_date).toLocaleDateString() : 'Datum unbekannt';

                        // Projekt-Details-String erstellen
                        const projectDetailsString = `Projekt: ${name}, Kategorie: ${project_category}, Klasse: ${project_class}, SOLL Phase: ${getPhaseName(predicted_phase)}, Startdatum: ${startDateFormatted}, Enddatum: ${endDateFormatted}`;

                        if (projectDetailsString) {
                            contextData[context] = projectDetailsString;
                        }
                    } else {
                        console.error('Projekt-Details nicht verfügbar oder das Array ist leer.');
                    }

                } catch (error) {
                    console.error('Failed to fetch project:', error);
                }
            } else if (context === 'aufgaben') {
                try {
                    const tasks = await getExtractedData(projectPath);
                    const tasksString = tasks.map(task => task.description).join(', ');

                    if (tasksString) {
                        contextData[context] = tasksString;
                    }
                } catch (error) {
                    console.error('Failed to fetch tasks:', error);
                }
            } else if (context === 'kbob' && contextData['projektdetails']) {
                const filteredDocuments = filterAndFormatDocuments(selectedPhase, selectedFachbereiche);

                // Format the response content
                const responseContent = 
`
## Projektdokumentation für ${project_details.name}   
**Projektkategorie:** ${project_details.project_category}    
**Projektklasse:** ${project_details.project_class}   
**Erforderliche Dokumente für ${selectedPhase} gemäss KBOB:**   
`;

                // Iterate over each fachbereich group to format documents
                const documentsByFachbereich = Object.keys(filteredDocuments).map(fachbereich => {
                    const documents = filteredDocuments[fachbereich];
                    const formattedDocuments = documents.map((doc, index) => 
`
${index + 1}. **Dokument:** ${doc.document_name}    
**Kategorien**: ${Object.values(doc.categories).filter(category => category.trim() !== "").join(", ") || "Keine Kategorien definiert"}  
**Beschreibung:** ${doc.description}   
**Dokumenttyp:** ${doc.document_types.join(', ')}   
`               ).join('\n');

                    return `### Fachbereich: ${fachbereich}\n${formattedDocuments}`;
                }).join('\n\n');

                // Append the grouped and formatted documents to the base response content
                contextData[context] = `${responseContent}${documentsByFachbereich}`;

            }
        }

        if (Object.keys(contextData).length === 0) {
            setModelResponse('Im Projekt wurden keine Daten zur Durchführung des Prozesses gefunden.');
            setIsProcessing(false); // Stop processing
            setExecutionStep(''); // Clear step description
            setCurrentStep(1);
            return;
        }

        setExecutionStep('Generieren der Dokumente...');

        const criteriaDetails = selectedCriteria.map((criteria) => ({
            name: criteria.name,
            description: criteria.description,
            requirements: criteria.return_schema.requirements.map(req => ({
                name: req.name,
                description: req.description
            }))
        }));

        const messages = [
            {
                role: "system",
                content: "You are a helpful assistant skilled at generating structured documents by validating information sources and formatting the data based on user input and context. You output only the results of your work. All generated outputs are in German in swiss writing style."
            },
            {
                role: "user",
                content: `I want to create a ${currentProcess.name} document. This is a ${currentProcess.category} type of process and its goal is: ${currentProcess.description}. Generate a document following these instructions: ${currentProcess.prompt}, based on the answers provided by the user: ${questionAnswerPairs}, additional information: ${additionalInfoPairs}, and other resources provided here: ${Object.values(contextData).join(', ')}. Please validate the provided source data, using the following evaluation criterias: ${JSON.stringify(criteriaDetails, null, 2)}. Include a summary of the validated data. Ensure the document adheres to standard conventions and formats for this type of document.`
            }
        ];

        if (isProjektdokumentation) {
            const response = contextData['kbob'];
            setModelResponse(response); 
        } else {
            const queryResponse = await queryGenerativeModel("openai", "gpt-4o-mini", messages);
            setModelResponse(queryResponse || 'Ergebnisse konnten nicht generiert werden.');     
        }
        
        setIsProcessing(false); // Stop processing
        setExecutionStep(''); // Clear step description
        setCurrentStep(1);
    };

    const phaseMapping = {
        "Phase 11": 1,
        "Phase 21": 2,
        "Phase 22": 3,
        "Phase 31": 4,
        "Phase 32": 5,
        "Phase 33": 6,
        "Phase 41": 7,
        "Phase 51": 8,
        "Phase 52": 9,
        "Phase 53": 10
    };

    const reversePhaseMapping = Object.keys(phaseMapping).reduce((acc, key) => {
        const value = phaseMapping[key];
        acc[value] = key; // Swap the key and value
        return acc;
    }, {});

    function getPhaseNumber(phaseName) {
        return phaseMapping[phaseName];
    }
    
    function getPhaseName(phaseNumber) {
        return reversePhaseMapping[phaseNumber];
    }
    
    const filterAndFormatDocuments = (phase, fachbereiche) => {
        const phaseNumber = getPhaseNumber(phase);
        let documents = [];
    
        if (phaseNumber === 11 || phaseNumber === 12) {
            documents = documentData
                .filter(doc => doc.sia_phasen.length === 0 && 
                               fachbereiche.some(fach => doc.fachbereiche.includes(fach)))
                .map(doc => formatDocument(doc));
        } else {
            documents = documentData
                .filter(doc => {
                    const matchesPhase = doc.sia_phasen.includes(phase);
                    const matchesFachbereiche = fachbereiche.some(fach => doc.fachbereiche.includes(fach));
                    return matchesPhase && matchesFachbereiche;
                })
                .sort((a, b) => {
                    const phaseNumA = getPhaseNumber(a.phase);
                    const phaseNumB = getPhaseNumber(b.phase);
                    return phaseNumA - phaseNumB;
                })
                .map(doc => formatDocument(doc));
        }
    
        // Group documents by fachbereich
        const groupedByFachbereich = {};
        documents.forEach(doc => {
            doc.fachbereiche.forEach(fach => {
                if (fachbereiche.includes(fach)) {  // Only include relevant fachbereiche
                    if (!groupedByFachbereich[fach]) {
                        groupedByFachbereich[fach] = [];
                    }
                    groupedByFachbereich[fach].push(doc);
                }
            });
        });
    
        return groupedByFachbereich;
    };
       

    const formatDocument = (doc) => ({
        categories: doc.categories,
        document_name: doc.document_name,
        description: doc.description,
        document_types: doc.document_types,
        fachbereiche: doc.fachbereiche
    });

    const renderMainScreen = () => (
        <>
            <Typography variant="h4" gutterBottom sx={{ textAlign: 'left', ml: 3, mt: 3, mb: 3 }}>
                Wilkommen!
            </Typography>
            <Typography variant="subtitle1" gutterBottom sx={{ textAlign: 'left', ml: 3 }}>
                Mit diesem Assistenten können Sie schnell auf die gängigsten Aufgaben zugreifen und diese auf einfache Weise erledigen.
            </Typography>
    
            <Divider sx={{ my: 3 }} />
    
            <Typography variant="h6" sx={{ ml: 3 }}>Vertragrelevant</Typography>
            <Grid container spacing={2} sx={{ p: 3 }}>
                {processes.filter(process => process.category === "Vertragrelevant").map(process => (
                    <Grid item xs={12} key={process.name}>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" sx={{ flex: 1 }}>{process.description}</Typography>
                            <Button
                                variant="outlined"
                                startIcon={<AccountTreeIcon />}
                                onClick={() => handleProcessSelect(process.name)}
                                sx={{ px: 4, py: 1 }}
                            >
                                {process.name}
                            </Button>
                            <Chip label={process.output} sx={{ ml: 2 }} />
                        </Box>
                    </Grid>
                ))}
            </Grid>
    
            <Divider sx={{ my: 3 }} />
    
            <Typography variant="h6" sx={{ ml: 3 }}>Berichtrelevant</Typography>
            <Grid container spacing={2} sx={{ p: 3 }}>
                {processes.filter(process => process.category === "Berichtrelevant").map(process => (
                    <Grid item xs={12} key={process.name}>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" sx={{ flex: 1 }}>{process.description}</Typography>
                            <Button
                                variant="outlined"
                                startIcon={<AccountTreeIcon />}
                                onClick={() => handleProcessSelect(process.name)}
                                sx={{ px: 4, py: 1 }}
                            >
                                {process.name}
                            </Button>
                            <Chip label={process.output} sx={{ ml: 2 }} />
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </>
    );
    
    const renderConfigurationScreen = () => {
        const isProjektdokumentation = currentProcess?.name === "Projektdokumentation"

        return (
        <>
            {/* <Typography variant="h5" gutterBottom>{currentProcess ? currentProcess.name : ''} - Konfiguration</Typography> */}
    
            {/* Projektauswahl */}
            <Typography variant="subtitle1" sx={{ mt: 3 }}>Projektauswahl</Typography>
            <FormControl fullWidth sx={{ }}>
                <InputLabel id="project-select-label">Projekt auswählen</InputLabel>
                <Select
                    labelId="project-select-label"
                    id="project-select"
                    required
                    value={selectedProject}
                    onChange={handleProjectSelect}
                >
                    {userProjects.map((projectItem) => (
                        <MenuItem key={projectItem} value={projectItem.split('/').pop()}>
                            {projectItem.split('/').pop()}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
    
            {/* Zusätzliche Informationen */}
            <Typography variant="subtitle1" sx={{ mt: 3 }}>Zusätzliche Informationen</Typography>
            <Grid container spacing={2}>
                {/* Safeguard for questions */}
                {currentProcess?.questions?.length ? (
                    currentProcess.questions.map((question, index) => (
                        <Grid item xs={12} key={index}>
                            <TextField
                                fullWidth
                                required
                                label={question}
                                value={questionAnswers[index] || ''} // Bind answer to the state
                                onChange={(e) => handleAnswerChange(index, e.target.value)} // Handle answer change
                            />
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary">
                            Keine Fragen verfügbar für diesen Prozess.
                        </Typography>
                    </Grid>
                )}
                {currentProcess?.name === 'Projektdokumentation' && (
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" sx={{ mt: 3 }}>Phase</Typography>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <InputLabel id="phase-select-label">Phase auswählen</InputLabel>
                            <Select
                                labelId="phase-select-label"
                                id="phase-select"
                                value={selectedPhase}
                                onChange={(e) => setSelectedPhase(e.target.value)}
                                required
                            >
                                {phases.map((phase) => (
                                    <MenuItem key={phase} value={phase}>
                                        {phase}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Typography variant="subtitle1">Fachbereiche</Typography>
                        <FormControl fullWidth>
                            <InputLabel id="fachbereiche-multiselect-label">Fachbereiche auswählen</InputLabel>
                            <Select
                                labelId="fachbereiche-multiselect-label"
                                id="fachbereiche-multiselect"
                                multiple
                                value={selectedFachbereiche}
                                //onChange={handleFachbereicheChange}
                                onChange={(event) => setSelectedFachbereiche(event.target.value)}
                                input={<OutlinedInput label="Fachbereiche auswählen" />}
                                renderValue={(selected) => selected.join(', ')}
                                required
                            >
                                {fachbereiche.map((fach) => (
                                    <MenuItem key={fach} value={fach}>
                                        <Checkbox checked={selectedFachbereiche.includes(fach)} />
                                        <ListItemText primary={fach} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                {formData.additionalInfo.map((info, index) => (
                    <Grid item xs={12} key={index} display="flex" alignItems="center">
                        <TextField
                            fullWidth
                            required
                            label={`Zusätzliche Information ${index + 1}`}
                            value={info}
                            onChange={(e) => handleAdditionalInfoChange(index, e.target.value)}
                        />
                        <IconButton
                            onClick={() => handleRemoveAdditionalInfo(index)}
                            sx={{ ml: 2 }}
                        >
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <Button
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={handleAddAdditionalInfo}
                        variant="outlined"
                    >
                        Weitere Informationen hinzufügen
                    </Button>
                </Grid>
            </Grid>
    
            {/* Informationsquellen */}
            <Typography variant="subtitle1" sx={{ mt: 3 }}>Informationsquellen</Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
                Daten aus den folgenden vordefinierten Quellen auswählen.
            </Typography>
            <Grid container spacing={2}>
                {Object.keys(context_dir).map((context) => (
                    <Grid item key={context}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={activatedContexts.includes(context)} // Pre-select based on activated contexts
                                    onChange={() => !isProjektdokumentation || (!['kbob', 'projektdetails'].includes(context) && handleContextToggle(context))}
                                    disabled={isProjektdokumentation && ['kbob', 'projektdetails'].includes(context)}
                                />
                            }
                            label={context_dir[context].label}
                        />
                    </Grid>
                ))}
            </Grid>
            <Typography variant="subtitle1" sx={{ mt: 3 }}>Quellenvalidierung</Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
                Kriterien zur Validierung der Datenquellen auswählen, wird der Antwort automatisch eine Zusammenfassung der bewerteten Informationen hinzugefügt.
            </Typography>
            <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel>Akzeptanzkriterien</InputLabel>
                <Select
                    multiple
                    value={selectedCriteria.map((criteria) => criteria.name)} // Map the selectedCriteria to just names for value comparison
                    onChange={handleCriteriaSelect}
                    input={<OutlinedInput label="Akzeptanzkriterien" />}
                    renderValue={(selected) => selected.join(', ')} // Display the selected names as comma-separated values
                >
                    {acceptanceCriteria.map((criteria) => (
                        <MenuItem key={criteria.name} value={criteria.name}>
                            {/* Check the box if the criteria is already in the selectedCriteria */}
                            <Checkbox checked={selectedCriteria.some((selected) => selected.name === criteria.name)} />
                            <ListItemText primary={criteria.name} secondary={criteria.description} />
                        </MenuItem>
                    ))}
                </Select>
                {selectedCriteria.length >= 5 && (
                    <Alert severity="warning">Sie können maximal 5 Kriterien auswählen.</Alert>
                )}
            </FormControl>
        </>
        )
    };
    

    const renderOutputScreen = () => (
        <>
            {/* <Typography variant="h5" gutterBottom>{currentProcess ? currentProcess.name : ''} - Output Presentation</Typography> */}
            {/* <Typography variant="body1" gutterBottom>
                Vorschlag:
            </Typography> */}
    
            {isEditing ? (
                // Show editable TextField when in editing mode
                <TextField
                    fullWidth
                    multiline
                    /* rows={10} */
                    value={editableResponse}
                    onChange={(e) => setEditableResponse(e.target.value)}
                    variant="outlined"
                    sx={{
                        flexGrow: 1, // Allow it to grow to take up available space
                        minHeight: '40vh', // Ensures it takes up a minimum height
                        maxHeight: '55vh', // Adjust as necessary for your layout
                        overflowY: 'auto' // Ensure scrollable content if it exceeds height
                    }}
                    
                />
            ) : (
                // Render Markdown when not in editing mode
                <Markdown align='left'>{modelResponse}</Markdown>
            )}
        </>
    );

    const renderCurrentScreen = () => {
        if (!currentProcess) return renderMainScreen();

        switch (currentStep) {
            case 0:
                return renderConfigurationScreen();
            case 1:
                return renderOutputScreen();
            default:
                return renderMainScreen();
        }
    };

    return (
        <>
            <IconButton 
                color="inherit" 
                onClick={handleOpen}
                className={animationClass} 
                sx={{ width: '56px', // Make sure width and height are the same
                    height: '56px', // Keep height equal to width
                    }}
            >
                <AssistantIcon />
            </IconButton>
    
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'auto' }} // Ensure scrolling for long content
            >
                <Box sx={{ width: '80vw', height: '80vh', bgcolor: 'background.paper', position: 'relative', overflowY: 'auto' }}>
                    <AppBar position="static" sx={{
                        background: 'linear-gradient(90deg, rgba(120,10,255,1) 0%, rgba(0,197,212,1) 25%, rgba(21,133,255,1) 75%, rgba(21,79,137,1) 100%)'
                    }}>
                        <Toolbar>
                            <AssistantIcon />
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginLeft: 2 }}>
                                {`Nukleus Assistent ${currentProcess ? '- ' + currentProcess.name : ''}  ${steps[currentStep] !== undefined ? '- ' + steps[currentStep] : ''}`}
                            </Typography>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    {isProcessing && <LinearProgress />} 
    
                    <Box sx={{ p: 3, maxHeight: '65vh', overflowY: 'auto', pointerEvents: isProcessing ? 'none' : 'auto' }}>
                        {renderCurrentScreen()}
                    </Box>
    
                    {currentProcess && currentStep >= 0 && (
                        <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, p: 2 }}>
                            {currentStep >= 0 && (
                                <Button
                                    onClick={handlePrevStep}
                                    variant="outlined"
                                    disabled={isProcessing}
                                    sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        margin: 2
                                    }}
                                >
                                    Zurück
                                </Button>
                            )}
                            {currentStep === 0 && (
                                <>
                                    <Button
                                        onClick={handleExecute}
                                        variant="contained"
                                        disabled={!isFormValid() || isProcessing} 
                                        startIcon={<PlayCircleOutlineIcon />}
                                        sx={{
                                            position: 'absolute',
                                            bottom: 0,
                                            right: 0,
                                            margin: 2
                                        }}
                                    >
                                        Ausführen
                                    </Button>
                                    <Typography variant="body2" color="textSecondary" sx={{ position: 'absolute', bottom: 0, right: 200, margin: 2 }}>
                                        {executionStep}
                                    </Typography>
                                </>
                            )}
                            {currentStep === 1 && (
                                <>
                                    <Button
                                        onClick={handleEditToggle}
                                        variant="contained"
                                        disabled={isProcessing}
                                        sx={{
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 100, // Adjust button position
                                            margin: 2
                                        }}
                                    >
                                        {isEditing ? 'Änderungen speichern' : 'Bearbeiten'}
                                    </Button>
    
                                    <Button
                                        onClick={() => {/* Logic to generate PDF/CSV */}}
                                        variant="outlined"
                                        disabled={isEditing}
                                        sx={{
                                            position: 'absolute',
                                            bottom: 0,
                                            right: 190, // Adjust button position
                                            margin: 2
                                        }}
                                    >
                                        Generate PDF/CSV
                                    </Button>
    
                                    <Button
                                        onClick={handleClose}
                                        variant="outlined"
                                        sx={{
                                            position: 'absolute',
                                            bottom: 0,
                                            right: 0,
                                            margin: 2
                                        }}
                                    >
                                        Assistent Beenden
                                    </Button>
                                </>
                            )}
                        </Box>
                    )}
                </Box>
            </Modal>
        </>
    );
}

export default Assistant;