import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography, MenuItem, InputLabel, Select, FormControl, IconButton, 
  TextField, Card, CardContent, Snackbar, Alert, Divider, Box,  } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers';
import deLocale from 'date-fns/locale/de';
import CloseIcon from '@mui/icons-material/Close';
import RecurringEventControls from './RecurringEventControls'; 
import ACMapping from './ACMapping';

const EditEvaluationTemplate = ({ userProjects, editData, handleEditChange, isViewAction, handleLocalSaveClick, onBackClick, action}) => {
  const [localEditData, setLocalEditData] = useState({ ...editData });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    setLocalEditData({ ...editData });
  }, [editData]);

  const validateAllFields = () => {
    if (!localEditData.name || !localEditData.description || !localEditData.start_date || !localEditData.end_date ||
      !localEditData.user_prompt || !localEditData.project) {
      setSnackbarMessage('Bitte füllen Sie alle erforderlichen Felder aus.');
      setOpenSnackbar(true);
      return false;
    }
    return true;
  };

  const onSave = () => {
    if (validateAllFields()) {
      handleLocalSaveClick(localEditData);
    }
  };

  const closeSnackbar = () => {
    setOpenSnackbar(false);
  };

  const [selectedProject, setSelectedProject] = useState({
    fullPath: '',
    projectName: editData.project ? editData.project.split('/').pop() : ''
  });

  const handleReportDataChange = (newData) => {
    handleEditChange('paths', newData);
  };

  const VerticalDivider = styled(Divider)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  }));

  const handleProjectSelection = (event) => {
    const fullPath = event.target.value;
    const projectName = fullPath.split('/').pop(); // Extracting the project name
  
    setSelectedProject({ fullPath, projectName });
    handleEditChange('project', projectName); // Assuming you still need to send this to a handler
  };

  const isEditAction = () => {
    if (action === 'edit') 
      return true;
    else
      return false;
  }

  return (
    <div style={{ padding: '20px' }}>
      <Grid container spacing={2} direction="column">
        
        {/* First Row */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={3}>
      
                  <Typography variant="h6" align="left">Planung</Typography>

                  <FormControl fullWidth margin="normal">
                    <InputLabel>Projekt</InputLabel>
                    <Select
                      labelId="project-label"
                      id="project"
                      required
                      value={selectedProject.projectName} // Use the full path for selection
                      onChange={handleProjectSelection}
                      label="Projekt"
                      disabled={isEditAction()}
                      sx={{ backgroundColor: 'white' }}
                    >
                      {userProjects.map((projectItem) => (
                        <MenuItem key={projectItem} value={projectItem.split('/').pop()}>{projectItem.split('/').pop()}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  
                  <TextField
                    label="Titel"
                    variant="outlined"
                    fullWidth
                    required
                    margin="normal"
                    disabled={isEditAction()}
                    value={editData.name}
                    onChange={(e) => handleEditChange('name', e.target.value)}
                  />
                  <TextField
                    label="Beschreibung"
                    variant="outlined"
                    required
                    fullWidth
                    margin="normal"
                    value={editData.description}
                    onChange={(e) => handleEditChange('description', e.target.value)}
                  />

                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2, marginBottom: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
                      <DatePicker
                        label="Startdatum"
                        required
                        value={editData.start_date}
                        onChange={(newValue) => handleEditChange('start_date', newValue)}
                        /* renderInput={(params) => <TextField {...params} sx={{ width: '48%' }} />} */
                      />
                      <DatePicker
                        label="Enddatum"
                        required
                        value={editData.end_date}
                        onChange={(newValue) => handleEditChange('end_date', newValue)}
                        /* renderInput={(params) => <TextField {...params} sx={{ width: '48%' }} />} */
                      />
                    </LocalizationProvider>
                  </Box>
                  <RecurringEventControls />
                </Grid>

                <VerticalDivider orientation="vertical" flexItem />

                <Grid item xs>            
                  <Grid item xs>
                    <Card>
                      <CardContent>
                        <ACMapping onDataChanged={handleReportDataChange} document={editData.paths} selectedProject={selectedProject.projectName}/>

                        <Box sx={{marginTop:2}}>
                          {/* Title */}
                          <Typography variant="h6" align="left" gutterBottom>
                            Anweisungen
                          </Typography>

                          {/* Subtitle */}
                          <Typography variant="subtitle1" align="left" gutterBottom>
                            Zusätzliche Hinweise für die Bewertung.
                          </Typography>

                          {/* TextField modified for large texts */}
                          <TextField
                            label="Anweisungen"
                            variant="outlined"
                            required
                            fullWidth
                            margin="normal"
                            multiline
                            rows={13}
                            value={editData.user_prompt}
                            onChange={(e) => handleEditChange('user_prompt', e.target.value)}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                    
                  </Grid>

                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

         <Grid item xs={12} style={{ textAlign: 'right' }}>

            <Button 
              variant="contained" 
              color="primary" 
              disabled={isViewAction}
              onClick={onSave} // Use the local handler 
              style={{ margin: '8px' }}>
              Speichern
            </Button>
            <IconButton onClick={onBackClick}>
              <CloseIcon />
            </IconButton>
          </Grid>

      </Grid>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity="warning" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EditEvaluationTemplate;