import React, { useEffect, useState } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { 
  Button, Breadcrumbs, Link, 
  Typography, IconButton, Tooltip, TextField, Box, InputAdornment, LinearProgress 
} from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditEvaluationTemplate from './ReportTemplatesEditEvaluation';
import ViewEvaluationTemplate from './ReportTemplatesViewEvaluation';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import ReportGridRowContextMenu from './ReportGridRowContextMenu';
import EditReportTemplate from './ReportTemplatesEditReport';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ClearIcon from '@mui/icons-material/Clear';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { useAppContext } from '../contexts/AppContext';
import { convertToDateString, convertToDate } from '../utilities/dateUtils';
import config from '../config';

const ListPage = ({ rows, onRowViewClick, onOptionSelect, onAddNewEvaluation, onAddNewReport, onCreateResult, qaReportCreationLoading, searchQuery, onSearchChange, handleClearSearch }) => {

  const contextMenuOptions = [
    { id: 'edit', label: 'Anpassen', icon: <EditIcon /> },
    { id: 'duplicate', label: 'Duplizieren', icon: <FileCopyIcon /> },
    { id: 'delete', label: 'Löschen', icon: <DeleteForeverIcon />, color: 'red' },
  ];

  const columns = [
    { field: 'name', headerName: 'Titel', flex: 1 },
    {
      field: 'project',
      headerName: 'Project',
      flex: 1,
      renderCell: (params) => {
        return (
          params.row.project.name
        )
      }
    },
    { field: 'description', headerName: 'Beschreibung', flex: 1 },
    { field: 'start_date', headerName: 'Startdatum', flex: 1 },
    { field: 'end_date', headerName: 'Enddatum', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <ReportGridRowContextMenu options={contextMenuOptions} onMenuItemClick={(option) => {
            onRowViewClick(row);
            onOptionSelect(option.id, row);
          }} />
          <Tooltip title="Ausführen" placement="top">
            <span>
              <IconButton
                onClick={() => onCreateResult(row)}
                aria-label="trigger-report"
                size="small"
                disabled={qaReportCreationLoading}
              >
                {qaReportCreationLoading ? <CircularProgress size={24} /> : <PlayCircleOutlineIcon color="primary" />}
              </IconButton>
            </span>
          </Tooltip>
        </>
      ),
    }
  ];

  const filteredRows = rows.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === 'string' &&
        value.toLowerCase().includes(searchQuery.toLowerCase())
    ) || (row.project && row.project.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <div style={{ width: '100%' }}>
      {qaReportCreationLoading ? (
        <LinearProgress />
      ) : (

        <React.Fragment>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: 1 }}>
            <TextField
              label="Suchen"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => onSearchChange(e.target.value)}
              sx={{ width: '25%' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClearSearch}
                      edge="end"
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box>
              <Tooltip title="Berichte ermöglichen es Ihnen, mehrere Auswertungen zu kombinieren.">
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onAddNewReport}
                    disabled={qaReportCreationLoading}
                    sx={{ marginRight: 2 }}
                  >
                    neuen Bericht erstellen
                  </Button>
                </span>
              </Tooltip>

              <Tooltip title="Auswertungen ermöglichen die Anwendung von Akzeptanzkriterien auf Dokumente.">
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onAddNewEvaluation}
                    disabled={qaReportCreationLoading}
                  >
                    Neue Bewertung
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Box>
          <div style={{ width: '100%' }}>
            <DataGridPro
              getRowId={(row) => `${row.name}-${rows.indexOf(row)}`}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
                pagination: { paginationModel: { pageSize: config.ui_settings.pagination_page_size } },
              }}
              pagination
              pageSizeOptions={config.ui_settings.pagination_page_size_options}
              rows={filteredRows}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              checkboxSelection
              disableRowSelectionOnClick
              disableMultipleSelection
              sx={{

                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: 'lightgrey',
                },
              }}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const DetailPage = ({ userData, userProjects, row, onBackClick, onSaveClick, action }) => {
  const [editData, setEditData] = useState({
    ...row,
    start_date: convertToDate(row.start_date),
    end_date: convertToDate(row.end_date),
  });

  const handleEditChange = (field, value) => {
    setEditData(prevState => ({ ...prevState, [field]: value }));
  };

  const renderData = (editData) => {
    return action === 'view' ? (<ViewEvaluationTemplate editData={editData} />) :
      action === 'edit' ? (<EditEvaluationTemplate userProjects={userProjects} editData={editData} handleLocalSaveClick={handleLocalSaveClick} handleEditChange={handleEditChange} onBackClick={onBackClick} action={action}/>) :
      action === 'create' ? (<EditEvaluationTemplate userProjects={userProjects} editData={editData} handleLocalSaveClick={handleLocalSaveClick} handleEditChange={handleEditChange} onBackClick={onBackClick} action={action}/>) :
        action === 'combine' ? (<EditReportTemplate editData={editData} userProjects={userProjects} handleLocalSaveClick={handleLocalSaveClick} handleEditChange={handleEditChange} onBackClick={onBackClick} />) :
          (<></>)
  };

  const handleLocalSaveClick = () => {
    if (!editData.start_date || !editData.end_date) {
      alert('Please fill all required fields.');
      return;
    }

    const dataToSave = {
      ...editData,
      start_date: convertToDateString(editData.start_date),
      end_date: convertToDateString(editData.end_date),
    };
    onSaveClick(dataToSave);
  };

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
          <IconButton onClick={onBackClick}>
            <ArrowBackIcon />
          </IconButton>
          <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
            <Link color="inherit" href="#" onClick={onBackClick}>
              Liste
            </Link>
            <Typography color="textPrimary" style={{ fontWeight: 'bold', fontSize: '1.45rem' }}>{row.name}</Typography>
          </Breadcrumbs>
          <Tooltip title="Erstellen Sie ein Projekt um Themen, Aufgaben und Dokumente zu sammeln und analysieren lassen. Alle Projekteinstellungen können hier bearbeitet werden.">
            <IconButton>
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
        {renderData(editData)}
      </div>
    );
};

export default function ReportTemplates() {
  const { userData, userProjects, fetchReportDefinitions, createReportDefinition, generateReportResult, editReportDefinition, removeReportDefinition, fetchReportResults } = useAppContext();
  const [selectedRow, setSelectedRow] = useState(null);
  const [action, setAction] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [reportDefinitions, setReportDefinitions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadReportTemplates = async () => {
    setIsLoading(true);
    try {
      const fetchedTemplates = await fetchReportDefinitions();
      if (fetchedTemplates) {
        setReportDefinitions(fetchedTemplates);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Failed to fetch report templates', error);
    }
  };

  useEffect(() => {
    loadReportTemplates();
  }, []);

  const handleTriggerCreateResult = async (row) => {
    console.log('Scenario Ausführen für: ', row.id);
    setIsLoading(true); // Start loading
    try {
      const response = await generateReportResult(row.name, row.project);
      console.log("Fetched Report data for creation: ", response);
      if (response != null) {
        fetchReportResults();
      }
      return response;
    } catch (error) {
      console.error(error);
      throw error; 
    } finally {
      setIsLoading(false); // End loading regardless of the result
    }
};

  const handleAddNewReport = () => {
    setSelectedRow({
      name: '',
      description: '',
      project: '',
      start_date: '',
      end_date: '',
      user_prompt: '',
      paths: [],
    });
    setAction('combine');
  };

  const handleAddNewReportTemplate = () => {
    setSelectedRow({
      name: '',
      description: '',
      project: '',
      start_date: '',
      end_date: '',
      user_prompt: '',
      paths: [],
    });
    setAction('create');
  };

  const handleDuplicateTemplate = (row) => {
    setSelectedRow({
      ...row,
      name: `${row.name} - Copy`
    });
    setAction('create');
  };

  const handleRowViewClick = (row) => {
    setSelectedRow(row);
    console.log('selected row: ', row.id);
  };

  const handleOptionSelect = (optionId, row) => {
    setAction(optionId);
    setSelectedRow(row);
    if (optionId === 'delete') {
      setDeleteModalOpen(true);
      console.log('selected row Delete: ', row);
      setDeleteRow(row);
    } else if (optionId === 'duplicate') {
      handleDuplicateTemplate(row);
    } else {
      console.log('selected row: ', row, ' for option ', optionId);
    }
  };

  const handleBackClick = () => {
    setSelectedRow(null);
    setAction(null);
  };

  const handleSaveClick = async (editData) => {
    try {
      if (action === 'edit') {
        await editReportDefinition(editData);
      } else {
        await createReportDefinition(editData);
      }
  
      await loadReportTemplates(); // Ensure this happens only after the previous operation
    } catch (error) {
      console.error(
        `Failed to ${action === 'edit' ? 'update' : 'add'} report`,
        error
      );
    } finally {
      setAction(null);
      setSelectedRow(null);
    }
  };
  

  const handleConfirmDelete = (row) => {
    if (row) {
      removeReportDefinition(row.project, row.name).then(() => {
        loadReportTemplates();
      });
    }
    setDeleteModalOpen(false);
    setAction(null);
    setSelectedRow(null);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
  };

  return (
    <div>
      {selectedRow && action !== 'delete' ? (
        <DetailPage
          userData={userData}
          userProjects={userProjects}
          row={selectedRow}
          onBackClick={handleBackClick}
          onSaveClick={handleSaveClick}
          action={action}
        />
      ) : (
        <ListPage
          rows={reportDefinitions}
          onRowViewClick={handleRowViewClick}
          onOptionSelect={(optionId, row) => handleOptionSelect(optionId, row)}
          onAddNewEvaluation={handleAddNewReportTemplate}
          onAddNewReport={handleAddNewReport}
          onCreateResult={(row) => handleTriggerCreateResult(row)}
          qaReportCreationLoading={isLoading}
          searchQuery={searchQuery}
          onSearchChange={setSearchQuery}
          handleClearSearch={handleClearSearch}
        />
      )}
      <DeleteConfirmationModal
        open={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleConfirmDelete}
        row={deleteRow}
      />
    </div>
  );
}
