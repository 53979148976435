import React, { useState } from 'react';
import { Box, Tooltip, Typography, Button, IconButton } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew'; // Import the external link icon
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'; 

// Extend dayjs with the plugin
dayjs.extend(isSameOrBefore);

// Function to calculate the color based on average score
const getColorFromScore = (averageScore) => {
  if (averageScore < 0.66) return 'red'; // Close to 0
  if (averageScore >= 0.66 && averageScore < 1.33) return 'yellow'; // Around 1
  return 'green'; // Close to 2
};

const ProjectOverviewHorizontalTimeline = ({ reports, project }) => {
  const { start_date, end_date } = project;
  const [activeReport, setActiveReport] = useState(null);
  const [startTime, setStartTime] = useState(dayjs().subtract(3, 'week'));
  const step_days = 5;

  // Ensure reports is an array
  const validReports = Array.isArray(reports) ? reports : [];

  // Group reports by day and calculate summary data
  const groupedReports = validReports.reduce((acc, report) => {
    const dateKey = dayjs(report.creation_date).format('YYYY-MM-DD'); // Group by day
    if (!acc[dateKey]) {
      acc[dateKey] = { reports: [], totalScore: 0, count: 0 };
    }
    acc[dateKey].reports.push(report);
    acc[dateKey].totalScore = (acc[dateKey].totalScore * (acc[dateKey].count - 1) + report.scores.reduce((p, c) => p + c.averageScore, 0) / report.scores.length) / acc[dateKey].count;
    acc[dateKey].count += 1;
    return acc;
  }, {});

  // Sort the dates
  const sortedDates = Object.keys(groupedReports).sort((a, b) => dayjs(a).diff(dayjs(b)));

  // Get today's date and the date 3 weeks ago
  //const today = dayjs();
  //const threeWeeksAgo = dayjs().subtract(3, 'week');
  const totalDays = 21; // 3 weeks = 21 days

  // Function to calculate the position of the report on the timeline (relative to the 3-week range)
  const getCirclePosition = (reportTimestamp, totalDays) => {
    const start  = startTime.startOf('day').valueOf();
    const reportTime = dayjs(reportTimestamp).startOf('day').valueOf();
    const dayNumber = Math.round((reportTime - start) / (1000 * 60 * 60 * 24)); // Convert to days
    
    // Calculate the percentage position based on the total number of days
    const percentagePosition = (dayNumber / totalDays) * 100;
  
    // Return the percentage position directly (no need to adjust by half the dot size)
    return `${percentagePosition}%`;
  };

  const handleNavigateBack = () => {
    setStartTime((prev) => {
      const newTime = prev.subtract(step_days, 'day');
      // Prevent going earlier than project start_date
      return newTime.isBefore(dayjs(start_date)) ? dayjs(start_date) : newTime;
    });
  };

  const handleNavigateForward = () => {
    setStartTime((prev) => prev.add(step_days, 'day'));
  };

  const isBackDisabled = startTime.isSameOrBefore(dayjs(start_date), 'day');

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center', 
        padding: 2, 
        width: '100%'
        }}>
      {/* Left navigation button */}
      <IconButton 
      onClick={handleNavigateBack}
      disabled={isBackDisabled}
      sx={{
        width: 30,
        height: 30, 
        margin: 0.75,
        border: '2px solid',
        borderColor: isBackDisabled ? 'grey.400' : 'primary.main',
        borderRadius: '50%',
        cursor: isBackDisabled ? 'not-allowed' : 'pointer'
      }}>
        <ArrowBack />
      </IconButton>

      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '60px', // Increased height to make room for larger dots
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {/* Horizontal line connecting the dots */}
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: '50%',
            height: '2px',
            backgroundColor: 'black', // Horizontal line color
          }}
        />

        {/* Render vertical lines for each day within the 3-week window */}
        {[...Array(22)].map((_, index) => {
          const dayPosition = (index / 21) * 100; // Calculate position for each day
          const currentDate = startTime.add(index, 'day'); // Add index as days to threeWeeksAgo
          const isMonday = currentDate.day() === 1;

          return (
            <React.Fragment key={`line-${index}`}>
                <Box
                key={`vertical-line-${index}`} 
                sx={{
                    position: 'absolute',
                    left: `${dayPosition}%`,
                    top: '25%',
                    bottom: '25%',
                    width: '1px',
                    color: 'gray',
                    backgroundColor: '#BDBDBD', // Vertical line color for each day
                }}
                />
                {isMonday && (
                    <Typography
                    key={`label-${currentDate.format('YYYY-MM-DD')}`}
                    variant='b'
                    sx={{
                        position: 'absolute',
                        bottom: '-15px',
                        left: `${dayPosition}%`,
                        color: 'textSecondary',
                    }}
                    >
                    {currentDate.format('DD MMM')}
                    </Typography>
                )}
            </React.Fragment>
            
          );
        })}

        {/* Render dots for each day within the 3-week window */}
        {sortedDates.map((date) => {
          const reportDate = dayjs(date);
          if (reportDate.isBefore(startTime) || reportDate.isAfter(startTime.add(3, 'week'))) {
            return null; // Skip reports outside the 3-week range
          }

          const dayReports = groupedReports[date].reports;
          const totalReports = groupedReports[date].count;
          const averageScore = groupedReports[date].totalScore / totalReports;
          const dotSize = 10 + totalReports * 5; // Size increases with the number of reports
          const dotColor = getColorFromScore(averageScore);

          return (
              <Tooltip
                key={`tooltip-${date}`} 
                title={
                    <Box key={'box-'+date} sx={{ p: 2, backgroundColor: 'white', borderRadius: '8px', color: 'black' }}>
                      {dayReports.map((report) => (
                        <Box key={`report-${report.id}`} sx={{ mb: 1 }}>
                          {/* Title with Link and External Button */}
                          <Button
                              sx={{ padding: 1 }} // Optional: Adjust padding for the button
                              variant="outlined" // Choose a variant, e.g., contained, outlined, etc.
                              endIcon={<OpenInNewIcon />} // Add the OpenInNewIcon to the right of the text
                              href={`${window.location.origin}/reports/instances/${report.name}?project=${report.project}&creation_date=${report.creation_date}`} // Link to the report
                              rel="noreferrer" // For security
                              >
                              {report.name}
                          </Button>
                         
                          
                          {/* Date Information */}
                          <Typography variant="body2" sx={{ color: 'black' }}>
                            Date: {dayjs(report.creation_date).format('dd DD, HH:mm')}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  }
                arrow
              >
                <Box
                  key={`dot-${date}`} 
                  sx={{
                    position: 'absolute',
                    left: `calc(${getCirclePosition(reportDate, totalDays)} - ${dotSize / 2.2}px)`, // Align dot with the vertical line
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    width: `${dotSize}px`,
                    height: `${dotSize}px`,
                    backgroundColor: dotColor,
                    border: '2px solid black', // Black border around the dot
                    borderRadius: '50%',
                    transform: activeReport === date ? 'scale(1.2)' : 'scale(1)',
                    transition: 'transform 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.2)',
                    },
                  }}
                  onMouseEnter={() => setActiveReport(date)}
                  onMouseLeave={() => setActiveReport(null)}
                />
              </Tooltip>
          );
        })}

        {/* Render the first date marker */}
        <Box
          key="start-marker"
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            marginBottom:1,
            marginTop:1,
            width: '2px',
            backgroundColor: 'black', // Line for the first date
          }}
        />
        {/* Render the current date marker */}
        <Box
          key="end-marker"
          sx={{
            position: 'absolute',
            left: '100%',
            top: 0,
            bottom: 0,
            marginBottom:1,
            marginTop:1,
            width: '2px',
            backgroundColor: 'red',
          }}
        />

        {/* Labels for start and end of the timeline */}
        <Typography
          variant='b'
          sx={{
            position: 'absolute',
            bottom: '-30px',
            left: 0,
            color: 'grey',
          }}
        >
          {startTime.format('DD MMM')}
        </Typography>
        <Typography
        variant='b'
          sx={{
            position: 'absolute',
            bottom: '-30px',
            right: 0,
            color: 'grey',
          }}
        >
          {startTime.add(3, 'week').format('DD MMM')}
        </Typography>
      </Box>
      {/* Right navigation button */}
      <IconButton 
      onClick={handleNavigateForward}
      sx={{
        width: 30,
        height: 30, 
        margin: 1,
        border: '2px solid',
        borderColor: 'primary.main', // Primary color outline
      }}>
        <ArrowForward />
      </IconButton>
    </Box>
  );
};

export default ProjectOverviewHorizontalTimeline;
