import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress} from '@mui/material';
import { useAppContext } from '../contexts/AppContext';
import { useAuth } from '../contexts/AuthContext';
import ProjectDetailView from './ProjectDetailView';
import ProjectDetailEdit from './ProjectDetailEdit';
import ListPage from './ProjectsListPage';

// DeleteConfirmationModal component
const DeleteConfirmationModal = ({ open, onClose, onConfirm, rowId }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Löschbestätigung</DialogTitle>
      <DialogContent>
        <DialogContentText>
        Sind Sie sicher, dass Sie dieses Element löschen möchten? Durch das Löschen des Projekts werden alle zugehörigen Dateien im Datenverwaltungsplattform verworfen. Dieser Vorgang ist nicht umkehrbar."
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Abbrechen
        </Button>
        <Button onClick={() => onConfirm(rowId)} color="primary" autoFocus>
          Bestätigen
        </Button>
      </DialogActions>
    </Dialog>
  );
};


export default function ProjectsGrid() {
    const { projects, createOrUpdateProject, deleteProject, getProjectDetailList, getUserData, getExtractedData, getCalculatedData, getMockTopics } = useAppContext();
    const [selectedRow, setSelectedRow] = useState(null);
    const [action, setAction] = useState(null);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteRowId, setDeleteRowId] = useState(null); // State to store the ID of the row to be deleted
    const [tasks, setTasks] = useState([]);
    const [latestSummary, setLatestSummary] = useState([]);
    const [calculatedData, setCalculatedData] = useState(null);
    const [mockTopics, setMockTopics] = useState([]);
    const { currentTenant } = useAuth();
    const [isLoading, setIsLoading] = useState(false); // State to track loading

    const handleAddNewProject = () => {
      // Set the selectedRow to a new empty project structure
      setSelectedRow({
        name: '',
        project_class: '',
        path: '',
        start_date: '',
        end_date: '',
        profile: '',
        // any other fields you need
      });
      setAction('create'); // Set action to 'edit' to use the DetailPage for creating a new project
    };

    const findMostRecentDocument = (documents) => {
      if (documents == null || documents.length === 0) {
        return null;  // Return null or an appropriate default value when the array is empty
      }
    
      return documents.reduce((latest, document) => {
        const currentDocDate = new Date(document.date);
        const latestDocDate = latest ? new Date(latest.date) : new Date(0); // Use the epoch (1970-01-01) as default if no latest yet
    
        return currentDocDate > latestDocDate ? document : latest;
      }, null);  // Initial value is null
    }
    
    // And adjust where you handle option selection
    const handleOptionSelect = async (optionId, row) => {
      setIsLoading(true); // Start loading
      if (optionId === 'view') {

        try {

          const mockTopics = await getMockTopics();
          if(mockTopics !== null) {
            setMockTopics(mockTopics);
          }

          const calculatedData = await getCalculatedData(row.path);
          if(calculatedData !== null) {
            setCalculatedData(calculatedData);
          }

          const [tasks, summaries] = await getExtractedData(row.path);
          setTasks(tasks);

          const mostRecentDocument = findMostRecentDocument(summaries);
          setLatestSummary(mostRecentDocument);

          setSelectedRow(currentRow => currentRow ? currentRow : row);
          setAction(currentAction => currentAction ? currentAction : optionId);
        } finally {
          setIsLoading(false); // End loading
        }
          
      } else if (optionId === 'delete') {
        // Delete handling
        setDeleteModalOpen(true);
        console.log('selected row Delete: ', row);
        setDeleteRowId(row.path); // Store the row ID for deletion
      } else {
        // Handle other options
        console.log('Edit row: ', row);
        setSelectedRow(currentRow => currentRow ? currentRow : row);
        setAction(currentAction => currentAction ? currentAction : optionId);
        setIsLoading(false); // End loading
      }
    };
  
    const handleBackClick = () => {
      setSelectedRow(null);
      setAction(null);
      console.log('selected row: ', null);
    };

    const handleSaveClick = (editData) => {
      // Use your updateProject function from the context
      console.log('Saving data', editData);
        
      createOrUpdateProject(editData)
      .then(() => {
        // Assuming updateProject returns a Promise, refresh the projects list after the update is successful
        getUserData(currentTenant).then((filtered_projects) => {
          getProjectDetailList(currentTenant, filtered_projects).then(() => {
            // Clear the action and selected row to go back to the list view
            setAction(null);
            setSelectedRow(null);
          });
        })
      })
      .catch(error => {
        // Handle any errors here
        console.error('Failed to add project', error);
      });
    
      
    };

    const handleConfirmDelete = () => {
     
      deleteProject(deleteRowId).then(() => {
        // After deletion, refresh the list or handle the UI update
        getUserData(currentTenant).then((filtered_projects) => {
          getProjectDetailList(currentTenant, filtered_projects).then(() => {
            setDeleteModalOpen(false);
            setAction(null);
            setSelectedRow(null);
          });
        })
      });
    };

    return (
      <React.Fragment>
        {isLoading && <LinearProgress />}
        {selectedRow && action === 'view' ? (
          <ProjectDetailView
            data={selectedRow}
            onBackClick={handleBackClick}
            onSaveClick={handleSaveClick}
            extractedData={tasks}
            calculatedData={calculatedData}
            summary={latestSummary}
            mockTopics={mockTopics}
          />
        ) : selectedRow && action === 'edit' ? (
          <ProjectDetailEdit
            data={selectedRow}
            onBackClick={handleBackClick}
            onSaveClick={handleSaveClick}
            action={action}
          />
        ): selectedRow && action === 'create' ? (
          <ProjectDetailEdit
            data={selectedRow}
            onBackClick={handleBackClick}
            onSaveClick={handleSaveClick}
            action={action}
          />
        ) : (
          <ListPage
            rows={projects}
            onOptionSelect={(optionId, row) => handleOptionSelect(optionId, row)}
            onAddNewProject={handleAddNewProject}
          />
        )}
        <DeleteConfirmationModal
          open={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleConfirmDelete}
          rowId={deleteRowId}
        />
      </React.Fragment>

    );
}