import React, { useState, useEffect } from 'react';
import { DataGridPro, gridExpandedSortedRowEntriesSelector, gridFilteredSortedRowEntriesSelector, useGridApiRef, useGridApiContext, useGridSelector, GridToolbar } from '@mui/x-data-grid-pro';
import { 
  Card, CardContent, CardHeader, Link, Avatar, Box, Menu, MenuItem,
  Chip, Typography, IconButton, Button, TextField, CircularProgress, 
  Tooltip, 
  Autocomplete} from '@mui/material';

// Import Recharts
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from 'recharts';
//import MarkdownRenderer from '../components/MarkdownRenderer';
import LaunchIcon from '@mui/icons-material/Launch';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { useAppContext } from '../contexts/AppContext';
import config from '../config';
//import { useAuth } from "../contexts/AuthContext";

const calculateScoreFromList = (list) => {
  const calculatedTotalScore = list.reduce((acc, entry) => {
    // Ensure each entry and score is valid before attempting to sum
    return acc + (entry.model.score || 0);
  }, 0);

  return calculatedTotalScore;
}

// Custom toolbar component to display pre-defined filter buttons
const CustomToolbar = () => {
  const apiRef = useGridApiContext();

  const handleFilterClick = (filterValue) => {
    apiRef.current.setFilterModel({
      items: [
        { 
          field: 'client_category', 
          operatorValue: 'equals', 
          value: filterValue
        }
      ],
    });
  };

  const handleClearFilters = () => {
    apiRef.current.setFilterModel({ items: [] });
  };

  return (
    <>
    {/* <GridToolbar /> */}
    <Box display="flex" justifyContent="flex-start" gap={1}  sx={{margin:2}}>
      
      <Chip label="Projektinhalte" onClick={() => handleFilterClick('Projektinhalte')} variant="outlined" />
      <Chip label="Baukosten" onClick={() => handleFilterClick('Baukosten')} variant="outlined" />
      <Chip label="Bauzeit" onClick={() => handleFilterClick('Bauzeit')} variant="outlined" />
      <Chip label="Verkehrssicherheit" onClick={() => handleFilterClick('Verkehrssicherheit')} variant="outlined" />
      <Chip label="Arbeitssicherheit" onClick={() => handleFilterClick('Arbeitssicherheit')} variant="outlined" />
      <Chip label="Filter löschen" onClick={handleClearFilters} variant="outlined" color="primary" />
    </Box>
    </>
  );
};

// Custom footer component to display total scores
const CustomFooter = ( apiRef ) => {
  const list = useGridSelector(apiRef.apiRef, gridExpandedSortedRowEntriesSelector);
  if(list !== undefined && list.length > 0) {
    const totalScore = calculateScoreFromList(list);
    return (
      <Typography variant="subtitle1" style={{ padding: 16, textAlign: 'right' }}>
        <b>Summe der thematischen Beiträge</b>: {totalScore}
      </Typography>
    );
  }
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
        <p className="label">{`Date: ${label}`}</p>
        {payload
          .filter(entry => entry.value !== null && entry.value !== undefined)  // Filter out categories with no data
          .map((entry, index) => (
            <p key={`item-${index}`} style={{ color: entry.color }}>
              {`${entry.name}: ${entry.value}`}
            </p>
          ))}
      </div>
    );
  }

  return null;
};


// New function to group data by 'document_acceptance_criteria' and aggregate by 'report_date'
const groupDataByCriteria = (data) => {
  const groupedData = {};
  const allDates = new Set();

  // Collect all unique dates
  data.forEach(item => {
    allDates.add(item.report_date);
  });

  // Initialize groupedData with empty arrays for each criteria
  data.forEach(item => {
    const { document_acceptance_criteria } = item;

    if (!groupedData[document_acceptance_criteria]) {
      groupedData[document_acceptance_criteria] = [];
    }
  });

  // Calculate the average score for each date per criteria
  Object.keys(groupedData).forEach(criteria => {
    allDates.forEach(date => {
      const entriesForDate = data.filter(item => item.document_acceptance_criteria === criteria && item.report_date === date);

      if (entriesForDate.length > 0) {
        const totalScore = entriesForDate.reduce((acc, entry) => acc + parseFloat(entry.average_score), 0);
        const averageScore = totalScore / entriesForDate.length;

        groupedData[criteria].push({
          report_date: date,
          average_score: averageScore
        });
      } else {
        groupedData[criteria].push({
          report_date: date,
          average_score: 0  // or set to null if you prefer
        });
      }
    });

    // Sort the data by date
    groupedData[criteria] = groupedData[criteria].sort((a, b) => new Date(a.report_date) - new Date(b.report_date));
  });

  return groupedData;
};


const ScoreChart = ({ data }) => {
  const groupedData = groupDataByCriteria(data);

  console.log("Data passed to chart:", groupedData); // Debugging: Check the data passed to the chart

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart margin={{ top: 5, right: 30, left: 20, bottom: 20 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="report_date" 
          type="category" 
          interval={0}  // Ensures all ticks are shown
          tick={{ textAnchor: 'middle' }} // Adjusts the label for better readability
          allowDuplicatedCategory={false}
        />
        <YAxis />
        <RechartsTooltip content={<CustomTooltip />} />
        <Legend 
          align="right"          // Align the legend to the right side
          verticalAlign="top" // Center the legend vertically
        />
        {Object.keys(groupedData).map((criteria, index) => (
          <Line
            key={index}
            type="monotone"
            data={groupedData[criteria]}
            dataKey="average_score"
            name={criteria}
            stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
            activeDot={{ r: 8 }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

const TopicList = ({ projectName }) => {
    const apiRef = useGridApiRef();
    //const { currentTenant } = useAuth();
    const { fetchReportResults, /* getSearchResultsInDocsStatelessV2, */ queryGenerativeModel } = useAppContext();

    const [expanded, setExpanded] = useState(true); // Default state is 
    const [dataGridRows, setDataGridRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);

    const [response, setResponse] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);

    const [recentInputs, setRecentInputs] = useState([]);

    const transformData = (apiData, project) => {
      const rows = [];

      // Filter the data by the specified project name
      const filteredData = apiData.filter(item => item.project === project);
    
      filteredData.forEach(item => {
        const { document, project, tenant, name, creation_date, text } = item;
    
        Object.values(document).forEach(doc => {

          // Update the path to omit the file name
          const updatedPath = doc.path.substring(0, doc.path.lastIndexOf('/')).replace(/^\/+/, '');
          const document_url = config.envs[config.active_env].domain + config.nextcloud_path + updatedPath + '&openfile=' + doc.nextcloud_id;

          doc.acceptance_criterias.forEach(criteria => {
            // Calculate total score excluding -1 values
            const totalScore = criteria.requirements.reduce((acc, requirement) => {
              if (requirement.score !== -1) {
                return acc + requirement.score;
              }
              return acc;
            }, 0);

            // Calculate the count of valid scores (excluding -1 values)
            const validCount = criteria.requirements.reduce((count, requirement) => {
              if (requirement.score !== -1) {
                return count + 1;
              }
              return count;
            }, 0);

            // Calculate the average score (if there are valid scores)
            const averageScore = validCount > 0 ? totalScore / validCount : 0;
            rows.push({
              document_name: doc.name,
              document_path: updatedPath,
              document_nextcloud_id: doc.nextcloud_id,
              document_acceptance_criteria: criteria.name,
              document_url,

              report_date: creation_date,
              report_name: name,
              report_project: project,
              report_tenant: tenant,

              average_score: averageScore.toFixed(2), // Rounded to two decimal places

              text: text
            });
          });
        });
      });
    
      return rows;
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true);
          const result = await fetchReportResults();
          const transformedData = transformData(result, projectName);
          setDataGridRows(transformedData);
          setFilteredData(transformedData); // Initialize with full data set
        } catch (error) {
          console.error('Failed to fetch report results:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, [fetchReportResults, projectName]);

    // Function to extract filtered data
    const getFilteredRows = (apiRef) => {
      return gridFilteredSortedRowEntriesSelector(apiRef.current.state).map(entry => entry.model);
    };

    // Using row index as a unique identifier
    const getRowId = React.useCallback((row, index) => `${row.report_name}-${row.report_date}-${row.document_nextcloud_id}-${row.document_acceptance_criteria}`, []);
  

    const handleFilterModelChange = () => {
      const filteredRows = getFilteredRows(apiRef);
      setFilteredData(filteredRows);
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const dataGridColumns = [
      { field: 'report_date', headerName: 'Datum', flex:0.5, },
      { field: 'report_name', headerName: 'Berichtergebniss', flex:0.35, 
        renderCell: (params) => {
          const text = params.row.report_name;
          return(
            <Chip label={
              <Link sx={{padding:1}} href={`${window.location.origin}/reports/instances/${params.row.report_name}?project=${params.row.report_project}&creation_date=${params.row.report_date}`} rel="noreferrer">
              {text} 
            </Link>
            }/>                                                          
          )
        }
      },
      { field: 'document_name', headerName: 'Dokument', flex:0.75, 
        renderCell: (params) => { 
          return(
            <Chip label={
              <Link sx={{padding:1}} target="_blank" href={`${params.row.document_url}`} rel="noreferrer">
              <LaunchIcon sx={{ fontSize: 16, marginRight: 0.5, marginBottom: 0}} />
              {params.row.document_name} 
              
            </Link>
            }/>                                                          
          )
        }
      },
      { field: 'document_acceptance_criteria', headerName: 'Thema / Akzeptanzkriterium', flex:0.75, },
      {
        field: 'average_score',
        headerName: 'thematischer Beitrag',
        flex:0.3, 
        renderCell: (params) => {
          return (   
            <Avatar>
              <Typography variant='body2'>{params.row.average_score}</Typography>
              </Avatar>            
          )
        }
    }
    ];

    const handleSelectionChange = () => {
      const selectedRowsMap = apiRef.current.getSelectedRows(); // Get a map of selected rows
      const selectedRowData = Array.from(selectedRowsMap.values()); // Convert the map to an array of row objects
      setSelectedRows(selectedRowData);  // Update the selected rows state
    };

    // Function to handle input change and track last 3 inputs
    const handleInputChange = (event, newInputValue) => {
      // Update only the input value, but do not modify recent inputs here
      setInputValue(newInputValue);
    };

    const handleQuestionData = async () => {
      setIsLoading(true);
      
      // Concatenate rowID data + text for each selected row
      const selectedData = selectedRows.map(row => {
        return `Report Name: ${row.report_name}, Report Date: ${row.report_date}, Document ID: ${row.document_nextcloud_id}, Acceptance Criteria: ${row.document_acceptance_criteria}, Text: ${row.text}`;
      }).join('\n\n'); // Separate each row by double new lines for readability

      const messages = [
          { role: 'system', content: "You are a Question-answering assistant, specialized in the construction and real-estate industry. You will be provided with user questions (USER_QUERY) regarding project reports (Kontext). Use this information to answer the user questions. Include the names of the files used to provide the answer. Never return the complete content of a document. Include the user query in the response at the beggining, labeled 'Frage:'. Provide an answer in German." },
          /* { role: 'assistant', content: 'REFERENCES: ' + referencesString }, */
          { role: 'assistant', content: 'Kontext: ' + selectedData }, 
          { role: 'user', content: 'USER_QUERY: ' + inputValue }
      ];
      const queryResponse = await queryGenerativeModel("openai", "gpt-4o-mini", messages);
      if (!queryResponse) queryResponse = 'Ergebnisse konnten nicht generiert werden. Bitte versuchen Sie es nochmals.'
      setResponse(queryResponse);

      // Update recent inputs after the question is sent
      if (inputValue && !recentInputs.includes(inputValue)) {
        setRecentInputs((prev) => {
          const updatedInputs = [inputValue, ...prev];
          return updatedInputs.slice(0, 3);  // Keep only the last 3 inputs
        });
      }

      setIsLoading(false);
      setInputValue(''); // Clear input field after adding
    }

    
    return (
      <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <CardHeader 
                action={
                <IconButton onClick={handleExpandClick} sx={{ marginLeft: 'auto', padding: 0 }}> </IconButton>
            }
            title={
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, cursor: 'pointer' }} onClick={handleExpandClick}>
                        <AutoAwesomeIcon color="secondary" sx={{ verticalAlign: 'middle', marginRight: 1, marginBottom:2 }} />
                        <Tooltip title={config.ui_messages.ai_disclaimer}>
                            <IconButton sx={{ padding: 0, marginRight: 2 }}> 
                                <InfoOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                        Thematische Beiträge aus Bewertungen
                    </Typography>
            } 
            sx={{ textAlign: 'left', paddingBottom: 0 }}
        />
            <CardContent sx={{ textAlign: 'left', flexGrow: 1, overflow: 'visible', '&:last-child': { paddingBottom: '16px' } }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <React.Fragment>
                
                <ScoreChart data={filteredData} />
                
                <DataGridPro 
                    rowHeight={46} // Default is 52
                    getRowId={getRowId}
                    initialState={
                      {
                        pagination: { paginationModel: { pageSize: 7 } /* config.ui_settings.pagination_page_size */  },
                        sorting: {
                          sortModel: [{ field: 'report_date', sort: 'asc' }], // Default sorting by 'report_date' in descending order
                        }
                      }
                    }
                    apiRef={apiRef}
                    width='100%' 
                    rows={dataGridRows} 
                    columns={dataGridColumns} 
                    checkboxSelection
                    pagination
                    pageSizeOptions={config.ui_settings.pagination_page_size_options}
                    onRowSelectionModelChange={handleSelectionChange}
                    onFilterModelChange={handleFilterModelChange}
                    components={{
                      Toolbar: GridToolbar, 
                    }}
                    /* slots={{
                        footer:  CustomFooter,
                        toolbar: CustomToolbar,
                    }}
                    slotProps={{
                        footer: { apiRef: apiRef },
                    }} */
                    /* onSelectionModelChange={(newSelection) => handleSelectionChange(newSelection)}  // Handle selection change
                    components={{
                      Toolbar: CustomToolbar,
                    }} */
                />
              </React.Fragment>
            )}
            <Autocomplete
              freeSolo
              options={recentInputs}  // Display the last 3 recent inputs
              value={inputValue}
              onInputChange={handleInputChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Frage stellen"
                  variant="outlined"
                  sx={{ marginTop: 2, marginBottom: 2 }}
                />
              )}
            />
            {/* Conditional rendering of the button or circular progress */}
            {isLoading ? (
              <CircularProgress sx={{ float: 'right', margin: '8px' }} />
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleQuestionData}
                disabled={selectedRows.length === 0 || inputValue.trim() === ''}
                sx={{ float: 'right' }}
              >
                Frage stellen
              </Button>
            )}
            <TextField
                fullWidth
                label="Antwort"
                multiline
                variant="outlined"
                value={response}
                InputProps={{
                    readOnly: true,
                }}
                sx={{
                  marginTop: 2,
                }}
                minRows={1}  // Start with one row, it will grow dynamically
                maxRows={Infinity}  // Let it grow without limit
            />
          </CardContent>  
        </Card>   
    )  
  }

  export default TopicList;