import React, { useEffect, useState } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { TextField, Box, IconButton, InputAdornment, LinearProgress, Typography, Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import DeleteConfirmationModal from './DeleteConfirmationModal';
import { useAppContext } from '../contexts/AppContext';
import { useNavigate } from "react-router-dom"; // Import useNavigate
import config from '../config';
import '../styles/ReportInstances.css';

export default function ReportInstancesGrid() {
  const { removeReportResult, fetchReportResults } = useAppContext();
  const [deleteRow, setDeleteRow] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reportResults, setReportResults] = useState([]);
  const [expandedGroups, setExpandedGroups] = useState({});
  const navigate = useNavigate();

  const loadReportResults = async () => {
    setIsLoading(true);
    try {
      const fetchedResults = await fetchReportResults();
      if (fetchedResults) {
        setReportResults(fetchedResults);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Failed to fetch report templates', error);
    }
  };

  useEffect(() => {
    loadReportResults();
  }, []);

  const handleRowClick = (object, event) => {
    // Check if the clicked row is a group row
    if (object.row.isGroup) {
      return;
    }

    // Ensure the row has a valid project field before proceeding
    if (object.row.project && object.row.creation_date) {
      navigate(`/reports/instances/${object.row.name}?project=${encodeURIComponent(object.row.project.split('/').pop())}&creation_date=${object.row.creation_date}`);
    } else {
      console.error('Invalid row data:', object.row);
    }
  };

  const handleDeleteOptionSelect = (row) => {
    setDeleteRow(row);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    removeReportResult(deleteRow.project, deleteRow.name, deleteRow.creation_date).then(() => {
      loadReportResults();
      setDeleteModalOpen(false);
      setDeleteRow(null);
    });
  };

  const handleClearSearch = () => {
    setSearchQuery('');
  };

  const toggleGroupVisibility = (reportDefinition) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [reportDefinition]: !prev[reportDefinition],
    }));
  };

  const color_grey = '#B0B0B0';

  const columns = [
    { field: 'project', headerName: 'Projekt', flex: 1, sortable: false, 
      renderCell: (params) => (
        <Typography
            style={{
                color: params.row.isGroup ? color_grey : 'inherit',
                fontStyle: params.row.isGroup ? 'italic' : 'normal',
            }}
        >
            {params.value}
        </Typography>
    ),
     },
    { field: 'creation_date', headerName: 'Erstellungsdatum', flex: 0.5, sortable: false, 
      renderCell: (params) => (
        <Typography
            style={{
                color: params.row.isGroup ? color_grey : 'inherit',
                fontStyle: params.row.isGroup ? 'italic' : 'normal',
            }}
        >
            {params.value}
        </Typography>
    ),
     },
    { field: 'description', headerName: 'Beschreibung', flex: 0.75, sortable: false, 
      renderCell: (params) => (
        <Typography
            style={{
                color: params.row.isGroup ? color_grey : 'inherit',
                fontStyle: params.row.isGroup ? 'italic' : 'normal',
            }}
        >
            {params.value}
        </Typography>
    ),
     },
    {
      field: 'actions',
      headerName: 'Aktionen',
      width: 100,
      sortable: false,
      renderCell: ({ row }) => {
        if (row.isGroup) {
          return null; // No delete action for group rows
        }
        return (
          <div className="action-cell-click">
            <IconButton 
              onClick={(event) => {
                event.stopPropagation();  
                handleDeleteOptionSelect(row)
              }}>
              
              <DeleteForeverIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  // Group reports by their definition name and sort them by creation date
  const groupedReports = reportResults.reduce((acc, report) => {
    const { name } = report;
    if (!acc[name]) {
      acc[name] = [];
    }
    acc[name].push(report);
    return acc;
  }, {});

  // Sort reports within each group by date
  Object.keys(groupedReports).forEach((group) => {
    groupedReports[group].sort((a, b) => new Date(b.creation_date) - new Date(a.creation_date));
  });

  // Flatten the grouped reports into a single array with grouping markers
  const flattenedReports = [];
  Object.keys(groupedReports).forEach((group) => {
    const reports = groupedReports[group];
    const isExpanded = expandedGroups[group];

    // Add a "group row" marker for the report definition
    flattenedReports.push({
      id: `${group}-group`,
      group,
      isGroup: true,
      project: reports[0]?.project,
      creation_date: reports[0]?.creation_date || null, // Use the latest report's date
      description: reports[0]?.description || '', 
      latestReport: reports[0],
      expanded: isExpanded,
      
    });

    // Always add the latest report
    flattenedReports.push({
      ...reports[0],
      isGroup: false,
      isLatest: true,
      id: `${reports[0].name}-latest`,
    });

    // Add the rest of the reports only if expanded
    if (isExpanded) {
      reports.slice(1).forEach((report) => {
        flattenedReports.push({
          ...report,
          isGroup: false,
          isLatest: false,
        });
      });
    }
  });

  const filteredRows = flattenedReports.filter((row) => {
    // Filter logic for search query, applied only to non-group rows
    if (row.isGroup) {
      return row.group.toLowerCase().includes(searchQuery.toLowerCase());
    }
    return row.name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  // Update the row count to exclude 'group' rows and count only report rows
  const actualRowCount = filteredRows.filter((row) => !row.isGroup).length;

  return (
    <div style={{ width: '100%' }}>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', margin: 1 }}>
            <TextField
              label="Suchen"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ width: '25%', marginRight: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearSearch} edge="end">
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
  
          <DataGridPro
            getRowId={(row) => row.id || `${row.name}-${row.creation_date}`}
            rows={filteredRows}
            slots={{ toolbar: GridToolbar,
              footer: () => (
                <Box sx={{ p: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  <Typography>{`Total: ${actualRowCount}`}</Typography>
                </Box>
              ),
             }}
            columns={[
              {
                field: 'group',
                headerName: 'Definition',
                sortable: false,
                flex: 1,
                renderCell: (params) => {
                    const group = params.row.group;
                    const isGroup = params.row.isGroup;
                    const reportsCount = groupedReports[group]?.length || 0;
            
                    if (isGroup) {
                        return (
                            <Box display="flex" alignItems="center">
                                {reportsCount > 1 ? (
                                    <Button
                                        variant="outlined"
                                        onClick={() => toggleGroupVisibility(group)}
                                        startIcon={
                                            expandedGroups[group] ? <ExpandLessIcon /> : <ExpandMoreIcon />
                                        }
                                    >
                                        {expandedGroups[group] ? `siehe nur letzter Bericht (${reportsCount})` : `siehe alle Berichte (${reportsCount})`}
                                    </Button>
                                ) : (
                                    <Typography variant="button" style={{ color: '#1976d2' }}>
                                        {group} ({reportsCount})
                                    </Typography>
                                )}
                            </Box>
                        );
                    }
                    return <Typography variant="button">{params.row.name}</Typography>;
                },
            },
            
            ...columns,
            ]}
            rowHeight={40}
            onRowClick={handleRowClick}
            checkboxSelection
            isRowSelectable={(params) => !params.row.isGroup}
            /* initialState={{
              sorting: {
                  sortModel: [{ field: 'project', sort: 'asc' }], // Pre-sort by 'project' in ascending order
              },
            }} */
            disableRowSelectionOnClick
            disableMultipleSelection
            sx={{
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
              },
              '& .MuiDataGrid-row:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: 'lightgrey',
              },
              '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                outline: 'none',
              },
              '& .Mui-selected, & .MuiDataGrid-cell--selected, & .MuiDataGrid-row--selected': {
                backgroundColor: 'transparent',
                color: 'inherit',
              },
            }}
            getRowClassName={(params) => {
              if (params.row.isGroup) return 'group-row'; // Class for group rows
              if (params.row.isLatest) return 'latest-report-row'; // Class for latest report
              return '';
          }}
          />
  
          <DeleteConfirmationModal
            open={isDeleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            onConfirm={handleConfirmDelete}
            rowId={deleteRow}
          />
        </>
      )}
    </div>
  );
}
