import React, { useEffect, useState } from 'react';
import {
  TextField, List, ListItem, ListItemText, Checkbox, Button,
  Typography, IconButton, CircularProgress, InputAdornment, Box,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useAppContext } from '../contexts/AppContext';
import { useAuth } from "../contexts/AuthContext";

const FolderSearch = ({ onSelect, project }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredFolders, setFilteredFolders] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [loading, setLoading] = useState(false);

  const { getFolderListStateless } = useAppContext();

  const transformPaths = (apiData) => {
    return apiData.map(item => {
      // Ensure the path has a leading forward slash
      const pathWithSlash = item.path.startsWith('/') ? item.path : `/${item.path}`;
  
      // Return the updated object
      return {
        ...item,
        path: pathWithSlash
      };
    });
  };

  // Use useEffect to load the folder list when the component mounts
  useEffect(() => {
    if (project) {
      const fetchFolders = async () => {
        setLoading(true);
        const results = await getFolderListStateless(
          project,
          true,
          false
        );
        
        const transformedData = transformPaths(results);
    
        if (transformedData) {
          const directories = transformedData.filter(item => item.is_dir);
          setFilteredFolders(directories);
        }
        setLoading(false);
      };

      fetchFolders();
    }
  }, [project]); // Re-run the effect if the project changes  

  const onLocalSelect = async (selectedFolders) => {
    const modifiedFolderData = selectedFolders.map(doc => {
      const pathSegments = doc.path.split('/').filter(segment => segment.trim() !== '');
      const name = pathSegments[pathSegments.length - 1];
    
      return {
        file_metadata: {
          id: doc.nextcloud_id,
          name: name,
          path: doc.path
        }
      };
    });
    

    onSelect(modifiedFolderData);
    setFilteredFolders([]);
    setSearchTerm('');
    /* setSelectedFolders([]); */
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setSelectedFolders([]);
  };

  const handleSearch = async () => {
    if (!project) return; // Ensure project is set before searching
    setLoading(true);
    const results = await getFolderListStateless(
      project,
      true,
      false
    );

    const transformedData = transformPaths(results);
    
    if (transformedData) {
      const directories = transformedData.filter(item => item.is_dir);
      setFilteredFolders(directories);
    }
    setLoading(false);
  };

  const handleSelect = (folder) => {
    setSelectedFolders((prev) => {
      const index = prev.findIndex((f) => f.nextcloud_id === folder.nextcloud_id);
      if (index >= 0) {
        // Folder is already selected, remove it from the array
        return prev.filter((_, i) => i !== index);
      } else {
        // Folder is not selected, add it to the array
        return [...prev, folder];
      }
    });
  };

  const handleClear = () => {
    setSearchTerm('');
    setFilteredFolders([]);
    setSelectedFolders([]);
  };

  // Filter the folders based on the searchTerm
  const displayedFolders = filteredFolders.filter(folder =>
    folder.path.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (!project) {
    console.log('Project is not Set for Report.');
    return <Typography variant="body">Bitte wählen Sie ein Projekt aus, um Dokumente zu suchen.</Typography>;
  }

  return (
    <Box>
      <Box mb={2} display="flex" justifyContent="space-between">
        <TextField
          label="Ordnerliste filtern nach..."
          value={searchTerm}
          onChange={handleSearchChange}
         /*  onKeyPress={(e) => {
            if (e.key === 'Enter') handleSearch();
          }} */
         InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSearch} disabled={!project}>
                {loading ? <CircularProgress size={24} /> : <SearchIcon />}
              </IconButton>
              <IconButton onClick={handleClear} disabled={!project || !searchTerm}>
                <ClearIcon />
              </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </Box>
      <List>
        {displayedFolders.map((folder) => (
          <ListItem key={folder.nextcloud_id} button onClick={() => handleSelect(folder)}>
            <Checkbox
              checked={selectedFolders.some((f) => f.nextcloud_id === folder.nextcloud_id)}
            />
            <ListItemText primary={folder.path} />
          </ListItem>
        ))}
      </List>

      {displayedFolders.length > 0 && (    
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
          <Button variant="contained" color="primary" onClick={() => onLocalSelect(selectedFolders)}>
            Ordner hinzufügen 
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default FolderSearch;
