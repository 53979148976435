import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import SearchToggle from './ACSearchToggle';
import ACAssign from './ACAssign';
import { isEqual } from 'lodash'; // Using lodash for deep comparison

const ACMapping = ({onDataChanged, document, selectedProject }) => {
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [assignments, setAssignments] = useState({});
  const [functionSearchTerm, setFunctionSearchTerm] = useState('');
  
  // Helper function to determine if the path represents a directory
  const isDirectory = (path) => {
    // Check if the path has no file extension or ends with a slash
    //return path.endsWith('/') || !path.includes('.');
    const normalizedPath = path.trim();

    if (normalizedPath.endsWith('/')) {
        return true;
    }

    const segments = normalizedPath.split('/');
    const lastSegment = segments[segments.length - 1];

    // Check if the last segment contains a dot
    if (lastSegment.includes('.')) {
      // Split by the dot and check if the part after the dot has spaces
      const partsAfterDot = lastSegment.split('.').slice(1).join('.');
      if (/\s/.test(partsAfterDot)) {
          return true;
      } else {
          return false;
      }
    }

    // Default assumption: if no period and no trailing slash, treat as a directory
    return true;

  };

  // Helper function to normalize the backend date format (if needed)
  const normalizeBackendDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date.toISOString().split('.')[0];
  };

  useEffect(() => {    
    // Whenever assignments change, notify the parent component
    const enrichedAssignments = selectedDocuments.map(doc => ({
      nextcloud_id: doc.file_metadata.id,
      name: doc.file_metadata.name,
      path: doc.file_metadata.path,
      acceptance_criterias: (assignments[doc.file_metadata.id] || []).map(assignment => assignment.name),
      filter_start_date: doc.file_metadata.filter_start_date || new Date().toISOString().split('.')[0],  // Use provided date or default to today
      filter_end_date: new Date().toISOString().split('.')[0],
      is_dir: isDirectory(doc.file_metadata.path) // Determine if it's a directory based on the path
      //filter_path_pattern: (leave for second iteration)
    }));

    onDataChanged(enrichedAssignments);

  }, [assignments, selectedDocuments]);

  useEffect(() => {
    if (selectedDocuments.length === 0) {
      setAssignments({});
    }
  }, [selectedDocuments]);

  useEffect(() => {
    if (!document || document.length === 0) return;

    const newSelectedDocuments = document.map(doc => ({
      file_metadata: {
        id: doc.nextcloud_id,
        name: doc.name,
        path: doc.path,
        filter_start_date: normalizeBackendDate(doc.filter_start_date), // Normalize backend date
        is_dir: isDirectory(doc.path),
      }
    }));
  
    const newAssignments = document.reduce((acc, doc) => {
      acc[doc.nextcloud_id] = doc.acceptance_criterias.map(criterion => ({ name: criterion }));
      return acc;
    }, {});

    const existingIds = selectedDocuments.map(doc => doc.file_metadata.id);
    const uniqueNewDocuments = newSelectedDocuments.filter(
      doc => !existingIds.includes(doc.file_metadata.id)
    );

    if(uniqueNewDocuments.length > 0) {
      const mergeUniqueDocuments = [...selectedDocuments, ...uniqueNewDocuments];
      setSelectedDocuments(mergeUniqueDocuments);
    } 

    if (!isEqual(newAssignments, assignments)) {
      setAssignments(newAssignments);
    }
  }, [document]);
  
  // Helper function to get yesterday's date in the format 'YYYY-MM-DDTHH:MM:SS'
  const getDaysFromTodayISOString = (days) => {
    const dft = new Date();
    dft.setDate(dft.getDate() - days); // Subtract one day
    return dft.toISOString().split('.')[0]; // Return without milliseconds
  };

  const handleDocumentSelect = (documents) => {
    const enrichedDocuments = documents.map((doc) => ({
      file_metadata: {
        id: doc.file_metadata.id,
        name: doc.file_metadata.name,
        path: doc.file_metadata.path,
        filter_start_date: doc.file_metadata.filter_start_date || getDaysFromTodayISOString(7), // Default to today if not set
        is_dir: isDirectory(doc.file_metadata.path), // Enrich with is_dir property
      },
    }));

    setSelectedDocuments((prev) => {
      const newDocs = enrichedDocuments.filter(
        (doc) => !prev.some((existingDoc) => String(existingDoc.file_metadata.id) === String(doc.file_metadata.id))
      );
      return [...prev, ...newDocs];
    });
  };
  

  const handleClearDocuments = () => {
    setSelectedDocuments([]);
  };

  const handleFunctionAssign = (docId, criteria) => {
    setAssignments((prev) => ({
      ...prev,
      [docId]: criteria,
    }));
  };

  const handleRemoveDocument = (doc) => {
    setSelectedDocuments((prev) => prev.filter((d) => d.file_metadata.id !== doc.file_metadata.id));
    setAssignments((prev) => {
      const newAssignments = { ...prev };
      delete newAssignments[doc.file_metadata.id];
      return newAssignments;
    });
  };

  const handleFunctionSearchChange = (term) => {
    setFunctionSearchTerm(term);
  };

  return (
    <Box sx={{ my: 1 }}>
      <Typography variant="h6" align="left" gutterBottom>
        Referenz Dokumenten
      </Typography>
      <Typography variant="subtitle1" align="left" gutterBottom sx={{ mb: 2 }}>
        Wissensbasen und Ressourcen, auf die im Scenario Bezug genommen wird.
      </Typography>
      
      <Grid container spacing={3}>
        <Grid item xs={12}>
        <SearchToggle
          initialIsDocumentSearch={selectedDocuments.length > 0 ? !isDirectory(selectedDocuments[0].file_metadata.path) : true} // Infer from path
          onSelect={handleDocumentSelect} 
          onClearSelected={handleClearDocuments} 
          project={selectedProject} 
        />
        </Grid>
        <Grid item xs={12}>
          <ACAssign
            selectedDocuments={selectedDocuments}
            onAssign={handleFunctionAssign}
            onRemoveDocument={handleRemoveDocument}
            onSearchChange={handleFunctionSearchChange}
            searchTerm={functionSearchTerm}
            assignments={assignments} // Pass assignments as prop
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ACMapping;