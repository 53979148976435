// ProjectComparisonMatrix.jsx
import React, { useState, useEffect } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { Button, CircularProgress, Typography, Tooltip, Box } from '@mui/material';
import config from '../config';
import '../styles/ProjectComparisonMatrix.css';

const ProjectComparisonMatrix = ({ selectedProjects, selectedReports, onClose }) => {
  const [criteriaScores, setCriteriaScores] = useState([]);
  const [loading, setLoading] = useState(true);

  // Step 1: Fetch Reports and Calculate Criteria Scores
  useEffect(() => {
        // Extract unique acceptance criteria
        const uniqueCriteria = new Set();
        const projectScores = {};

        selectedReports.forEach((report) => {
          const projectId = report.project;
          // Skip if the project is not in the selectedProjects array
          if (!selectedProjects.includes(projectId)) {
              return;
          }

          const { document } = report;

          if (!projectScores[projectId]) {
            projectScores[projectId] = {};
          }

          Object.values(document).forEach((doc) => {
            doc.acceptance_criterias.forEach((criteria) => {
              uniqueCriteria.add(criteria.name);

              const validScores = criteria.requirements
                .map((req) => req.score)
                .filter((score) => score !== -1);
              
              const sumOfScores = validScores.reduce((acc, score) => acc + score, 0);
              const countOfScores = validScores.length;
              
              if (!projectScores[projectId][criteria.name]) {
                // Initialize structure to track cumulative data
                projectScores[projectId][criteria.name] = {
                    totalScore: 0, // Cumulative score across reports
                    totalCount: 0, // Total number of scores across reports
                    reports: [], // List of contributing reports
                };
              }
      
              // Update cumulative score and count
              projectScores[projectId][criteria.name].totalScore += sumOfScores;
              projectScores[projectId][criteria.name].totalCount += countOfScores;
      
              // Add the current report to the contributing reports
              projectScores[projectId][criteria.name].reports.push({
                  reportName: report.name,
                  scores: validScores,
              });
            });
          });

          // After processing all reports, calculate the average score for each criterion
          Object.keys(projectScores).forEach((projectId) => {
            Object.keys(projectScores[projectId]).forEach((criterion) => {
                const data = projectScores[projectId][criterion];
                data.avgScore = data.totalCount > 0 ? data.totalScore / data.totalCount : 0;
            });
          });
        });

        // Convert data for the DataGrid rows
        const rows = selectedProjects.map((project) => {
            const row = { id: project, project };
    
            Object.entries(projectScores[project] || {}).forEach(([criterion, value]) => {
                row[criterion] = value.avgScore; // Add the average score
                row[`${criterion}_reports`] = value.reports; // Add contributing reports
            });
    
            return row;
        });

        setCriteriaScores({ columns: Array.from(uniqueCriteria), rows });
        setLoading(false);

  }, [selectedProjects, selectedReports]);

  // Ensure criteriaScores and columns are defined
  const columnsData = criteriaScores?.columns || [];

  const actualRowCount = criteriaScores.rows?.length || 0;

  // Helper function to interpolate colors between the min and max values
  const getColorForScore = (score) => {
    if (score === null || score === undefined) return ''; // No color for missing values

    // Color definitions for the gradient
    const minColor = { r: 245, g: 149, b: 100 }; // Orange
    const maxColor = { r: 101, g: 244, b: 153 }; // Green

    // Calculate the ratio between min (0) and max (2)
    const ratio = Math.min(Math.max(score / 2, 0), 1);

    // Interpolate each color channel
    const r = Math.round(minColor.r + ratio * (maxColor.r - minColor.r));
    const g = Math.round(minColor.g + ratio * (maxColor.g - minColor.g));
    const b = Math.round(minColor.b + ratio * (maxColor.b - minColor.b));

    return `rgb(${r}, ${g}, ${b})`;
  };

  // Filter columns to include only those with at least one non-null value
  const nonEmptyColumns = columnsData.filter((criterion) =>
    criteriaScores?.rows?.some((row) => row[criterion] !== null && row[criterion] !== undefined)
  );

  // Minimum width for the project column to ensure readability
  const projectColumnWidth = 250; 

  // Calculate the remaining width after assigning the project column
  const remainingWidth = window.innerWidth - projectColumnWidth - config.ui_settings.drawer_width;
  // Get the dynamic width for each criterion column based on the number of columns
  const numCriteriaColumns = criteriaScores.columns?.length || 1;
  const criteriaColumnWidth = remainingWidth / numCriteriaColumns;

  // Step 2: Render Matrix with DataGrid Pro
  const columns = [
    {
      field: 'project',
      headerName: 'Projekte',
      width: projectColumnWidth,
    },
    ...(nonEmptyColumns.map((criterion) => ({
      field: criterion,
      headerName: criterion,
      width: criteriaColumnWidth,
      align: 'center',
      renderCell: (params) => {
        const value = params.value;
        const contributingReports = params.row[`${params.field}_reports`] || []; // Get the reports arra

        return (
          <Tooltip
            title={
              contributingReports.length > 0 ? (
                <div>
                  <Typography variant="subtitle2">Beiträge von {contributingReports.length} Berichte</Typography>
                  {/* {contributingReports.map((report, index) => (
                    <Typography key={index} variant="body2">
                      {report.reportName || `Report ${index + 1}`}
                    </Typography>
                  ))} */}
                </div>
              ) : (
                'No reports contributing'
              )
            }
          >
            <Typography
              style={{
                padding: '10px',
                borderRadius: '4px',
              }}
            >
              {value !== null && value !== undefined ? value.toFixed(2) : '-'}
            </Typography>
          </Tooltip>
        )},/* 
        <Typography
          style={{
            /* backgroundColor: getColorForScore(params.value),
            padding: '10px',
            borderRadius: '4px',
          }}
        >
          {params.value !== null && params.value !== undefined ? params.value.toFixed(2) : '-'}
        </Typography> 
        ),*/
    }))),
  ];

  return (
    <div style={{ width: '100%', overflow: 'visible' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 16 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={onClose} 
        >
          Zurück
        </Button>
      </div>
      {/* <Typography variant="h6" mt={2}>Project Comparison Matrix</Typography> */}
      {loading ? (
        <CircularProgress />
      ) : (
        <div style={{display: 'flex', flexGrow: 1}}>
          <DataGridPro
            rows={criteriaScores.rows}
            columns={columns}
            slots={{ 
              toolbar: GridToolbar,
              footer: () => (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '8px 16px',
                        borderTop: '1px solid #e0e0e0',
                        backgroundColor: '#f9f9f9',
                    }}
                >
                    <Typography variant="body2" color="textSecondary">
                        Legende: Min (0) - Max (2)
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Total: {actualRowCount}
                    </Typography>
                    
                </Box>
            ),
            
            }}
            autoHeight
            pageSizeOptions={[5, 10, 20]}
            getCellClassName={(params) => {
              if (params.value == null || params.field === 'project') return '';
              return params.value >= 1 ? 'hot' : 'cold'; // Adjust this logic as needed
            }}
            sx={{
              '& .MuiDataGrid-columnHeaders': { backgroundColor: '#f5f5f5' },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProjectComparisonMatrix;
