import React, { useState, useEffect } from 'react';
import { LinearProgress , Card, CardHeader, CardContent, Paper,
  Typography, Box, Button, TextField, Link, ListItem, List, ListItemText } from '@mui/material';
/* import ReactMarkdown from 'react-markdown'; */
import MarkdownRenderer from '../components/MarkdownRenderer';
/* import rehypeReact from 'rehype-react'; */
/* import remarkGfm from 'remark-gfm'; */
/* 
import remarkMath from 'remark-math';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize'; */

import { useAppContext } from '../contexts/AppContext';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ReportGenerator from './ReportPDFGenerator'; // Assuming the file path is correct

import config from '../config';

const ReportView = ({ refreshReportList }) => {
  const { id: report_name } = useParams(); // Use useParams to get the id
  const { editReportResult, fetchReportResults } = useAppContext();
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate(); // Hook for navigation

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectName = queryParams.get('project');
  const creationDate = queryParams.get('creation_date');

  useEffect(() => {
    let isMounted = true; // Track whether the component is still mounted
    if (report_name && isMounted) {
      console.log("Fetching report instance with id:", report_name); // Debug statement
      //getReportInstanceById(id)
      fetchReportResults(projectName, report_name, creationDate)
        .then(data => {
          console.log("API response data:", data); // Debug statement
          if (data && isMounted) {
            setCurrentData({ ...data[0] });
            setLoading(false); // Set loading to false after data is fetched
            console.log("Setting Data for Instance", data[0]);
          } else {
            console.error("No data found for the given ID");
            setLoading(false); // Set loading to false if no data is found
          }
        })
        .catch(error => {
          console.error("Error fetching data", error);
          setLoading(false); // Set loading to false in case of an error
        });
    }
  
    return () => {
      isMounted = false; // Cleanup function to set isMounted to false
    };
  }, [report_name]);

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleSaveClick = async () => {
    try {
      //await updateReportInstance(currentData.id, currentData);
      await editReportResult(currentData.project, currentData.name, currentData.creation_date, currentData.text);
      setIsEditMode(false);
      refreshReportList();  // Trigger the refresh of the report instances list
    } catch (error) {
      console.error("Error saving data", error);
    }
  };

  const handleCancelClick = () => {
    setIsEditMode(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentData({
      ...currentData,
      [name]: value,
    });
  };

  const handleBackClick = () => {
    navigate('/reports/instances'); // Navigate back to the reports list
  };

  if (loading) {
    return <LinearProgress />; // Show a loading indicator if data is being fetched
  }

  if (!currentData) {
    return <Typography variant="body1">No data available</Typography>; // Show a message if no data is available
  }

  // Filter out 'markdown_report' and process remaining entries
  const filteredValues = Object.entries(currentData.document)
  .filter(([key, _]) => key !== 'markdown_report')  // Filter out the 'markdown_report'
  .map(([_, value]) => value);  // Map to get only values
  const global_response = currentData.text;
  const getPathWithoutFile = (fullPath) => 
    fullPath.split('/').slice(0, -1).join('/');

  const getTrafficLight = (score) => {
    // Implement your traffic light logic here
    return score == 2 ? '🟢' : score == 1 ? '🟡' : score == 0 ? '🔴' : 'k.A.';
  };

  const generateDocumentComponents = (documents) => {
    return documents.map((doc, docIndex) => (
        <Box key={docIndex} className="document" mb={3}>
            <Typography variant="h4">{doc.name}</Typography>
            <Typography variant="body1">Path: {doc.path}</Typography>
            {doc.acceptance_criterias.map((criteria, criteriaIndex) => (
                <Box key={criteriaIndex} component="ol" start={criteriaIndex + 1} ml={3} mt={2}>
                  <Typography variant="body1">{criteria.name}</Typography>
                  <List component="ul">
                      {criteria.requirements.map((req, reqIndex) => (
                          <ListItem key={reqIndex}>
                              <ListItemText
                                  primary={
                                      <React.Fragment>
                                          <strong>Anforderung:</strong> {req.name}
                                          <br />
                                          <strong>Bewertung:</strong> {getTrafficLight(req.score)} ({req.score})
                                          <br />
                                          <strong>Begründung:</strong> {req.justification}
                                      </React.Fragment>
                                  }
                              />
                          </ListItem>
                      ))}
                  </List>
                </Box>
            ))}
        </Box>
    ));
  };

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
      <CardHeader
        title={`Berichtdetails - ID: ${currentData.name}`}
        sx={{ textAlign: 'left', paddingBottom: 5 }}
        action={
          <>
            <Button
              startIcon={<ArrowBackIcon />}
              onClick={handleBackClick}
              sx={{ border: '1px solid', borderRadius: 1, marginRight: 1 }}
            >
              Zurück
            </Button>
            {!isEditMode && (
              <>
                <Button 
                  onClick={handleEditClick} 
                  sx={{ border: '1px solid', borderRadius: 1, marginRight: 1 }}
                >
                  Anpassen
                </Button>

                <ReportGenerator report_data={currentData}/>
              </>
            )}
          </>
        }
      />
      <CardContent sx={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', textAlign: 'left', flexGrow: 1, '&:last-child': { paddingBottom: '16px' } }}>
        <header style={{ fontFamily: 'Zen Kaku Gothic New', width: '100%', padding: '20mm', paddingBottom:0, boxSizing: 'border-box', position: 'relative',  display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <Box>
            <table style={{ borderCollapse: 'collapse', width: '600px' }}>
              <thead>
                <tr>
                  <th style={{ padding: '8px', borderBottom: '1px solid black'  }}>Änderung (creation/editdate)</th>
                  <th style={{ padding: '8px', borderBottom: '1px solid black'  }}>Methode</th>
                  <th style={{ padding: '8px', borderBottom: '1px solid black'  }}>Version</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: '8px'}}>{currentData.creation_date}</td>
                  <td style={{ padding: '8px' }}>Akzeptanzkriterium - Methode</td>
                  <td style={{ padding: '8px'}}>1</td>
                </tr>
              </tbody>
            </table>
          </Box>
          <Box>
            <img src="/nukleus.png" alt="Nukleus Logo" style={{ height: '50px' }} />
          </Box>
        </header>

        <Box sx={{ fontFamily: 'Zen Kaku Gothic New', width: '100%', padding: '20mm', paddingBottom:0,  boxSizing: 'border-box', position: 'relative', textAlign: 'left', flexGrow: 1, '&:last-child': { paddingBottom: '16px' } }}>
          <Typography variant="h5" style={{ margin: '8px 0' }}>
            <strong>Titel:</strong>
            <Link href={`/reports/templates`} /* target="_blank" */ sx={{ marginLeft: 1 }}>
              {currentData.name}
            </Link>
          </Typography>
          
          <Typography variant="h5" style={{ margin: '8px 0' }}>
            <strong>Verweise:</strong>
          </Typography>

          <Box sx={{ mb:4 }}>
            <List aria-label="document list">
              {filteredValues.map((doc, docIndex) => (
                <div key={docIndex} elevation={3} sx={{ m: 2, p: 2}}>
                  <ListItem>
                    <ListItemText primary={
                      <Link href={config.envs[config.active_env].domain + config.nextcloud_path + getPathWithoutFile(doc.path.substring(1).substring(0)) + '&openfile=' + doc.nextcloud_id} target="_blank" variant="body2" color="primary" style={{ fontWeight: 'bold' }}>
                        {doc.name}
                      </Link>
                    } />
                  </ListItem>

                  <Typography variant="subtitle" sx={{ ml: 2, mt: 1 }}>Akzeptanzkriterien:</Typography>
                  <List component="div" disablePadding sx={{ ml: 4, mt: 1 }}>
                  {doc.acceptance_criterias.map((criterion, criterionIndex) => (
                    <li key={criterionIndex}>
                      <Typography variant="body2">- {criterion.name}</Typography>
                    </li>
                  ))}
                  </List>
                </div>
              ))}
            </List>
          </Box>

          <Typography variant="h5" style={{ margin: '8px 0' }}>
          <strong>Bewertungen:</strong>
          </Typography>

          <Typography variant="body1" style={{ margin: '8px 0' }}>
            {filteredValues.length > 0 ? (
                generateDocumentComponents(filteredValues)
              ) : (
                'Es wurden keine Bewertungen vorgenommen.'
              )}
          </Typography>

          <Typography variant="h5" style={{ margin: '8px 0' }}>
          <strong>Benutzeranfrage:</strong>
          </Typography>
          <Typography variant="body1" style={{ margin: '8px 0' }}>
           {currentData.user_prompt}
          </Typography>

          <Typography variant="h5" style={{ margin: '8px 0' }}>
          <strong>Zusammenfassung der Ergebnisse:</strong>
          </Typography>

          <Typography variant="body1" style={{ margin: '8px 0' }}>
            {isEditMode ? (
              <TextField
                fullWidth
                variant="outlined"
                name="text"
                value={global_response}
                onChange={handleInputChange}
                multiline
              />
            ) : (
              <MarkdownRenderer 
                markdown={global_response} 
              />
              
            )}
          </Typography>

          {isEditMode && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button onClick={handleCancelClick} sx={{ mr: 1 }}>Abbrechen</Button>
              <Button onClick={handleSaveClick} variant='contained' color="primary">Speichern</Button>
            </Box>
          )}
        </Box>

        <footer style={{ bottom: '0', left: '0', right: '0', padding: 0, height: '40mm', width: '100%', backgroundImage: 'url(/nk-footer.png)', backgroundSize: 'cover', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <Box sx={{ textAlign: 'right', color: 'white', padding:10, paddingBottom:5, boxSizing: 'border-box' }}>
            <Typography variant="body2">nukleus AG</Typography>
            <Typography variant="body2">digital intelligence</Typography>
          </Box>
        </footer>
      </CardContent>
    </Card>
  );
}

export default ReportView;
