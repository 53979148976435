import React, { useState } from 'react';
import { 
  MenuItem, Button, TextField, Grid, 
  Select, InputLabel, FormControl, IconButton, 
  Snackbar 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import deLocale from 'date-fns/locale/de';
import config from '../config';
import { useAppContext } from '../contexts/AppContext';
import NavigationBreadcrumbs from './NavigationBreadcrumbs';

const ProjectDetailEdit = ({ data,  onSaveClick, onBackClick, action }) => {
  const convertToDate = (dateString) => {
    if (!dateString) return null; // Handle empty or undefined dates
    const parsedDate = new Date(dateString);
    return isNaN(parsedDate.getTime()) ? null : parsedDate;
  };

  const [editData, setEditData] = useState({
    ...data,
    start_date: convertToDate(data.start_date) || null,
    end_date: convertToDate(data.end_date) || null,
  });
  
  const [ifValidDates, setIfValidDates] = useState(false);
  const [ifValidName, setIfValidName] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  
  const { userProjects } = useAppContext();

  const isDateValid = (startDate, endDate) => {
    if (startDate && endDate) {
      // Strip the time component by setting hours, minutes, seconds, and milliseconds to zero
      const start = new Date(startDate.setHours(0, 0, 0, 0));
      const end = new Date(endDate.setHours(0, 0, 0, 0));
      
      return end > start; // End date must be after the start date
    }
    return false; // Return false if either date is missing
  };
  

  const isValidName = (name) => {
    const regex = /^[a-zA-Z0-9\s]+$/; // Regex for valid name (letters, numbers, spaces)
    const sanitizeName = (name) => name.trim().replace(/\s+/g, '_').toLowerCase();
    const sanitizedInputName = sanitizeName(name);
    const projectNames = userProjects.map(path => {
      const projectName = path.split('/').pop();
      return sanitizeName(projectName);
    });
    
    return {
      isValid: regex.test(name) && !projectNames.includes(sanitizedInputName),
      isUnique: !projectNames.includes(sanitizedInputName),
      matchesRegex: regex.test(name),
    };
  };

  const handleEditChange = (field, value) => {
    let newStartDate = null;
    let newEndDate = null;
    // Handle name validation
    if (field === 'name') {
      const nameValidation = isValidName(value);
      if (!nameValidation.isValid) {
        if (!nameValidation.isUnique) {
          setSnackbarMessage('Der gewählte Name existiert bereits. Bitte wählen Sie einen anderen Namen.');
        } else if (!nameValidation.matchesRegex) {
          setSnackbarMessage('Der Name enthält unzulässige Zeichen. Erlaubt sind Buchstaben, Zahlen und Leerzeichen.');
        }
        setIfValidName(false);
        setSnackbarOpen(true);
      } else {
        setIfValidName(true);  // Reset if the name is now valid
        setSnackbarOpen(false);  // Close any existing snackbar
      }
    }
  
    // Handle date validation
    if (field === 'start_date' || field === 'end_date') {
      const newDate = value;
      if (isNaN(newDate.getTime())) {
        setSnackbarMessage('Ungültiges Datum. Bitte ein gültiges Datum wählen.');
        setSnackbarOpen(true);
        return;
      }

      // Ensure dates are valid
      newStartDate = field === 'start_date' ? newDate : editData.start_date;
      newEndDate = field === 'end_date' ? newDate : editData.end_date;

      const formatted_date = new Date(value);

      setEditData((prev) => ({ ...prev, [field]: formatted_date }));

      if (!isDateValid(newStartDate, newEndDate)) {
        setSnackbarMessage('Das Enddatum muss nach dem Startdatum liegen.');
        setIfValidDates(false);
        setSnackbarOpen(true);
        return;
      } else {
        setIfValidDates(true);
      }
    }

    if (field !== 'start_date' && field !== 'end_date') {
      setEditData((prev) => ({ ...prev, [field]: value }));
    }
  };
  

  const areRequiredFieldsPresentAndValid = (editData) => {
    const requiredFields = ['name', 'project_class', 'project_category', 'profile', 'start_date', 'end_date'];
    const areFieldsPresent = requiredFields.every(field => editData[field] !== undefined && editData[field] !== '');
    
    // Check if dates are valid and name is valid
    return areFieldsPresent && ifValidName && ifValidDates;
  };

  const handleLocalSaveClick = () => {   
    // Validate required fields
    if (!areRequiredFieldsPresentAndValid(editData)) {
      setSnackbarMessage('Bitte füllen Sie alle erforderlichen Felder aus.');
      setSnackbarOpen(true);
      return;
    }
    
    // Save: Convert dates to ISO string before saving
    const updatedData = {
      ...editData,
      start_date: editData.start_date.toISOString().split('.')[0],
      end_date: editData.end_date.toISOString().split('.')[0],
    };

    // All validations passed, save the project data
    onSaveClick(updatedData);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const tooltipText = "Erstellen Sie ein Projekt um Themen, Aufgaben und Dokumente zu sammeln und analysieren lassen. Alle Projekteinstellungen können hier bearbeitet werden.";

  return (
    <React.Fragment>
      <NavigationBreadcrumbs onBackClick={onBackClick} projectName={editData.name} tooltipText={tooltipText} />

      <div style={{ padding: '20px' }}>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
          <Grid item xs={4}>
            <TextField
              label="Name"
              value={editData.name}
              required
              onChange={(e) => handleEditChange('name', e.target.value)}
              fullWidth
              disabled={action === 'edit'}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth required>
              <InputLabel>Zielkategorie</InputLabel>
              <Select
                label="Zielkategorie"
                required
                value={editData.project_category}
                onChange={(e) => handleEditChange('project_category', e.target.value)}
              >
                {config.ui_messages.goal_category.map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth required>
              <InputLabel>Nutzungskategorie</InputLabel>
              <Select
                label="Nutzungskategorie"
                required
                value={editData.project_class}
                onChange={(e) => handleEditChange('project_class', e.target.value)}
              >
                {config.ui_messages.usage_category.map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth required>
              <InputLabel>Profil</InputLabel>
              <Select
                label="Profil"
                value={editData.profile}
                onChange={(e) => handleEditChange('profile', e.target.value)}
              >
                {config.ui_settings.profile_options.map((option, index) => (
                  <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
            <Grid item xs={2}>
              <DatePicker
                required
                label="Startdatum"
                value={editData.start_date || null}
                onChange={(e) => {
                  handleEditChange('start_date', e);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={2}>
              <DatePicker
                required
                label="Enddatum"
                value={editData.end_date || null}
                onChange={(e) => {
                  handleEditChange('end_date', e);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </LocalizationProvider>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button 
              variant="contained" 
              color="primary" 
              disabled={!areRequiredFieldsPresentAndValid(editData)}
              onClick={handleLocalSaveClick} 
              style={{ marginRight: '8px' }}>
              Vortfahren
            </Button>
            <IconButton onClick={onBackClick}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </React.Fragment>
  );
};

export default ProjectDetailEdit;